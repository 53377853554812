import React, { useState } from "react";
// Dresovi
import DomaciDres from "../../../Assets/Images/Oprema/Domaći-prednji.png";
import GostujuciDres from "../../../Assets/Images/Oprema/Gostujući-prednja.png";
import GolmanskiZuti from "../../../Assets/Images/Oprema/Golmanski-žuti-prednji.png";
import GolmanskiCrveni from "../../../Assets/Images/Oprema/Golmanski-crveni-prednji.png";

import TotalSports from "../../../Assets/Images/Sponzori/Total-Sports.png";
import { Link } from "react-router-dom";

export default function Dresovi() {
  const [position, setPosition] = useState(0);

  const moveLeft = () => {
    setPosition((position - 1 + 4) % 4);
  };

  const moveRight = () => {
    setPosition((position + 1) % 4);
  };

  const preventContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <>
        <div className="kit-slider">
          <Link className="kit-button" to="https://www.total-sport.hr/">
            <img
              className="slider-img"
              src={TotalSports}
              alt="Total Sports"
              loading="lazy"
            />
          </Link>
          <button id="left-slide" className="btn-slide" onClick={moveLeft}>
            <i className="fi fi-rs-angle-left"></i>
          </button>
          <div className="slider">
            {position === 0 && (
              <div className="kit-box">
                <h3>DOMAĆI DRES</h3>
                <img
                  className="kit-img"
                  src={DomaciDres}
                  alt="Domaći dresovi"
                  onContextMenu={preventContextMenu}
                  style={{ userSelect: "none" }}
                  loading="lazy"
                />
              </div>
            )}
            {position === 1 && (
              <div className="kit-box">
                <h3>GOSTUJUĆI DRES</h3>
                <img
                  className="kit-img"
                  src={GostujuciDres}
                  alt="Gostujući dresovi"
                  onContextMenu={preventContextMenu}
                  style={{ userSelect: "none" }}
                  loading="lazy"
                />
              </div>
            )}
            {position === 2 && (
              <div className="kit-box">
                <h3>GOLMANSKI DRES, ŽUTI</h3>
                <img
                  className="kit-img"
                  src={GolmanskiZuti}
                  alt="Golmanski dres, žuti"
                  onContextMenu={preventContextMenu}
                  style={{ userSelect: "none" }}
                  loading="lazy"
                />
              </div>
            )}
            {position === 3 && (
              <div className="kit-box">
                <h3>GOLMANSKI DRES, CRVENI</h3>
                <img
                  className="kit-img"
                  src={GolmanskiCrveni}
                  alt="Golmanski dres, crveni"
                  onContextMenu={preventContextMenu}
                  style={{ userSelect: "none" }}
                  loading="lazy"
                />
              </div>
            )}
          </div>
          <button id="right-slide" className="btn-slide" onClick={moveRight}>
            <i className="fi fi-rs-angle-right"></i>
          </button>
        </div>
      </>
    </>
  );
}
