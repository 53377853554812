export default function Main(props) {
  const preventContextMenu = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <div className="main-home">
        {/* Your content */}
        <img
          src={props.desktopBackground}
          alt="NK Podsused Teren"
          onContextMenu={preventContextMenu}
          style={{ userSelect: "none" }}
          loading="lazy"
        />
        <h1>{props.title}</h1>
      </div>
      <style>
        {`
          /* For desktop */
          @media only screen and (min-width: 1024px) {
            .main-home img {
              content: url('${props.desktopBackground}');
            }
          }

          /* For phone */
          @media only screen and (max-width: 850px) {
            .main-home img {
              content: url('${props.phoneBackground}');
            }
          }
        `}
      </style>
    </>
  );
}
