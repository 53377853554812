import Main from "../Home/Main";
import NKSloboda1977 from "../../../Assets/Images/Klub/NK-Sloboda-1977.jpg";
import NKSloboda1966 from "../../../Assets/Images/Klub/NK-Sloboda-1966.jpg";
import NKHidroelektra from "../../../Assets/Images/Klub/NK-Hidroelektra-vodstvo.jpg";
import Seniori2013 from "../../../Assets/Images/Klub/Seniori-2013.jpg";
import Zagici2014 from "../../../Assets/Images/Klub/Zagici2014.jpg";
import Limaci2014 from "../../../Assets/Images/Klub/Limaci2014.jpg";
import Pioniri2014 from "../../../Assets/Images/Klub/Pioniri2014.jpg";
import Seniori2020 from "../../../Assets/Images/Klub/Seniori2020.jpg";
import Klub2021 from "../../../Assets/Images/Klub/Klub2021.jpg";
import StoGodina from "../../../Assets/Images/Klub/100Godina.jpg";
import { useEffect } from "react";

export default function About() {
  useEffect(() => {
    document.title = "NK Podsused | O klubu";
  }, []);
  return (
    <>
      <Main
        desktopBackground={NKSloboda1977}
        phoneBackground={NKSloboda1977}
        title="POVIJEST KLUBA"
      />

      <div className="section">
        <div className="calendar">
          <Calendar
            year="1921"
            text=" Osnovan prvi nogometni klub u Podsusedu pod imenom “CROATIA”
            Podsused.
            Pokrovitelj Kluba bila je Tvornica cementa Podsused. Prvi
            predsjednik kluba bio je Fridrih Fišer (tadašnji direktor tvornice),
            tajnik kluba bio je Filip Klešić, Tornada Ivan. Prvo igralište se
            nalazilo u krugu Tvornice cementa."
          />
          <Calendar
            year="1936 - 1940"
            text="Igralište se seli u Susedsko polje na imanje Gregurić.Uglavnom su se
            igrale prijateljske utakmice u gradu Zagrebu i okolici."
          />
          <Calendar
            year="1940"
            text=" Tvornica cementa u svom krugu ponovo gradi nogometno igralište s
            boljim uvjetima, gdje se odigravaju domaće utakmice."
          />
          <Calendar
            year="1944"
            text="Odigrana zadnja utakmica pod imenom „CROATIA“ u jesen ratne 1944. g.
            u Samoboru, (7:2 za „CROATIA“-u)."
          />
          <Calendar
            year="1945"
            text="Po završetku II. svijetskog rata, tijekom jeseni 1945. u Podsusedu je održana godišnja skupština klub, a klub dobiva ime NK “DOLOMIT”. Za predsjednika kluba izabran je Dragutin Karlović, za tajnika Rudolf Fingerhut, a za blagajnika Ivan Goričar. Prva momčad imala je zelene dresove na kojima je pisalo DOLOMIT"
          />
          <Calendar
            year="1948"
            text="Klub postaje sportsko društvo sa sedam sekcija"
          />
          <Calendar
            year="1949"
            text="Klub ostaje bez nogometnog igrališta u Tvornici cementa. Kondicijski treninzi održavali su se na na Starom gradu (brdo iznad Podsuseda gdje se nalaze ruševine srednjovjekovnog dvorca čiji je jedan od vlasnika bio Franjo Tahi), dok se rad s loptom provodio po podsusedskim livadama i dvorištima."
          />
          <Calendar
            year="1952"
            text="Prva prvenstvena utakmica između NK „DOLOMIT“ i NK „Građevinar“ na novom igralištu sagrađenom na sadašnjoj lokaciji Kupališni put u Podsusedu odigrana je 2. travnja 1952.g. a završila je rezultatom 9:1 za domaćina."
          />
          <Calendar
            year="1952 - 1953"
            text="Krajem 1952. i početkom 1953. klub bilježi slabe rezultate što zbog odlaska dijela igrača na odsluženje vojnog roka, što zbog igrača koji odlaze u druge klubove."
          />
          <Calendar
            year="1953"
            text="Tijekom jeseni 1953., klub mijenja ime u NK „Sloboda“. Na čelu kluba je tadašnji direktor Tvornice cementa Ivan Groh, tajnik Branko Popović, blagajnik Antun Čok te odbornici Dragutin Karlović, Rudolf Fingerhut, M.Kalčiček, S.Gabina. "
          />
          {/*    <Calendar
            year="1953 - 1967"
            text="Od 1953. do 1967. Klub se natječe s promjenjivim rezultatima."
          /> */}
          <Calendar
            year="1968"
            text="U Podsusedu se stvara nova generacija igrača, kad se počinju nizati uspjesi, a kvaliteta igre se podiže na višu razinu.Velika večina igrača seniorske momčadi stasala je u mlađim uzrastima Kluba. "
          />
          <CalendarImage img={NKSloboda1966} name="NK Sloboda 1966" />
          <Calendar
            year="1973 - 1978"
            text="Podsusedski klub postiže najbolje rezultate svog postojanja. Tri godine zaredom osvaja se prva mjesta u tadašnjim ligama (I. Zagrebačka liga, Zagrebačka nogometna liga i Zagrebačka zona) te je 1977. osvojen KUP Zagrebačke regije. Iste godine na kvalifikacijama za ulazak u III. jugoslavensku ligu momčad NK „Sloboda“-a osvaja 1. mjesto te se 1977/78 takmiči u tadašnjoj III. jugoslavenskoj ligi.Za NK „Sloboda“ nastupali su slijedeći igrači: Željko Bergman-Prka, Vlado Pavec, Vlado Sedmak, Vlado Piškor, Ivica Nikolić, Željko Crnogaj, Željko Rožić, Tomislav Hršak, Mirko Buden, Milivoj Štefan-Fazo (kapetan), Boris Pišković-Bobač, Ivica Ozmec, Štefanec-Čeva, Branko Borovec s trenerom Vladimirom Aumilerom."
          />
          <CalendarImage img={NKSloboda1977} name="NK Sloboda 1977." />
          <Calendar
            year="1978"
            text="Podsusedski klub mjenja ime u NK „Hidroelektra“ pod kojim imenom nastupa do 1992. godine. Seniorska momčadi „Hidroelektra“-e 1978. godine odigrala je prijateljsku utakmicu s prvom momčadi NK „Hajduk“iz Splita. Utakmica je odigrana na starom hajdukovom igralištu „Pod murvom, a rezultat utakmice bio je 3:1 za „Hajduk“. Momčad „Hajduk“-a nastupila je u slijedećem sastavu: Nižetić, Primorac, Krstičević, Zlatko Vujović, Peruzović, Zoran Vujović, Žungul, Mužinić, Jovanić, Đorđević, Šurjak, Rožić, Juričko, Kalinić, Luketin, Gudelj, Bonacin, Maričić, a momčad „Hidroelektra“ u sastavu: Borovec, Perkušić, Rožić, Viskala, Hršak, Đukić, Milivoj Štefan, Štibuhar, Balaban, Tufegđić, Kožarec, Z. Borovec, Stanić, Modrić, Vučković, Željko Jagustović, Albert Sedmak, Filipović.) Takmičenje Kluba u tadašnjoj Hrvatskoj ligi je najveći uspjeh kluba, a za ostvarene rezultate uz odličan igrački kadar najzaslužniji su bili treneri Dragutin Kvaternik, Viktor Hršak, Vlado Aumiler i Branko Knez, te vodstvo Kluba Franjo Mikačić, Branko Marciuš, Josip Glažar, Gojko Čikara (generalni direktor Tvornice cementa Podsused) i Zdenko Juras. Zbog agresije na RH i početka Domovinskog rata Klub nakratko prestaje s funkcioniranjem (uključivanje večine vodstva Kluba i seniorskog igračkog kadra u obranu RH)."
          />
          <CalendarImage
            img={NKHidroelektra}
            name="Igrači i vodstvo NK HIDROELEKTRA u „Bijelom salonu“ NK Hajduk (stadion „Pod murvom)"
          />
          <Calendar
            year="1992"
            text="U Klub dolaze Željko Bašić, Nedjeljko Bengez, Juraj Barilović, Marijan Krog, Jadranko Dobra, Viktor Hršak te se počinje takmičiti u novoustrojenim stupnjevima takmičenja Zagrebačkog nogometnog saveza. U samo tri sezone klub je iz treće Zagrebačke lige došao do treće Hrvatske lige. U to vrijeme inicijativom tadašnjeg čelništva Kluba izgrađena je zaštitna ograda oko nogometnog igrališta, uređene prostorije i svlačionice kluba te je instalirano novo centralno grijanje, čime su u bitnome popravljeni uvijeti treniranja i odigravanja domaćih utakmica."
          />
          <Calendar
            year="2001"
            text="Održana je proslava 80. godišnjice postojanja kluba kojom prilikom su nagrađeni bivši i tadašnji odgovorni i zaslužni članovi kluba."
          />
          <Calendar
            year="2011"
            text=" U cilju omogućavanja bavljenja nogometom dijece iz Podsuseda i područja koje gravitira Podsusedu, 2011. godine grupa građana s područja Podsuseda osniva neprofitabilnu udrugu građana NK PODSUSED. U osnivanju udruge sudjelovali su Damir Marciuš, Darinka Kokić, Čedo Čakmak, Romeo Čižmešija, Dario Mieržvinski, Josip Ivanuš, Michael Baklaić, Stjepan Balić. Za predsjednika Skupštine kluba izabran je Stijepan Balić, za predsjednika Izvršnog odbora izabran je Damir Marciuš, a za tajnika je izabran Čedo Čakmak. Svakako treba napomenuti veliku potporu roditelja djece koji nastupaju za Klub u osnivanju i radu. U jesen iste godine novoregistrirana udruga NK Podsused od Grada Zagreba dobiva na korištenje nogometno igralište s pripadajučom infrastrukturom. Velikim entuzijazmom i velikom financijskom potporom prijatelja Kluba, pristupilo se uređenju i adaptaciji klupskih prostorija i infrastrukture. Za istaknuti je sponzore: „MARCIUŠ“ d.o.o. (vlasnik Damir Marciuš), Auto kuća „Dojkić“ d.o.o. (vlasnik Draško Dojkić), Auto servis „Dojkić“ d.o.o (vlasnik Damir Dojkić), Pizzerija „Petar Rauch“ (vlasnik Romeo Čižmešija), „ELOTERM“ d.o.o. (vlasnik Michael Baklaić) i „KDS“ (vlasnik Krunoslav Držaić), kao i ostale članove Kluba koji su svojim radom pridonijeli stvaranju boljih uvijeta za treniranje i takmičenje. Za trenera seniorske momčadi imenovan je Zvonimir Pavić kojemu je povjereno okupljanje i vođenje seniorske momčadi. Uz seniorsku momčad takmiče se i tri uzrasta omladinskog pogona (zagići, limaći i ml. pioniri). Za voditelja omladinskog pogona određen je Dario Mieržvinski, a treneri su bili Čedo Čakmak i Saša Kuharić. Sve četiri momčadi počele su s takmičenjem u najnižim rangovima takmičenja koja su se odigravala u sklopu Zagrebačkog nogometnog saveza (ZNS)."
          />
          <Calendar
            year="2011 - 2012"
            text="U takmičarskoj 2011/2012. III. lige ZNS-a, seniorska momčad osvojila je treče mjesto. Momčadi omladinskog pogona osvojila su mjesta oko sredine tablice, a igrač limača podsusedskog Kluba Emanuel Bošković proglašen je za najboljeg strijelca lige (19 golova) u kojoj se momčad limaća takmičila. "
          />
          <Calendar
            year="2012 - 2013"
            text="U takmičarskoj 2012/2013. trenersku „palicu“ seniorske momčadi preuzeo je Ivica Krauz-Deda te je seniorska momčad osvojila 1. mjesto III. lige ZNS-a. Nakon osvajanja 1. mjesta III. lige ZNS-a seniorska momčad trebala se takmičiti u višem rang takmičenja (II. Liga ZNS-a) ali zbog uvijeta (momčad juniora) koje NK Podsused nije ispunjavao (sukladno Pravilniku o natjecanju ZNS-a), seniorska momčad nastavila je s takmičenjem u III. ligi ZNS-a."
          />
          <CalendarImage
            img={Seniori2013}
            name="Momčad NK PODSUSED-prvaci III.lige ZNS-a 2012/2013."
            igraciGore="Stoje: Marin Matijević, Martin Šutalo, Branimir Selimović, Mario Novak, Ivan Kroflin, Marko Birač, Matej, Jurić, Mario Žagar, Tin Kosovec, Marko Kroflin, Ivica Krauz-Deda (trener), Dario Grabušić."
            igraciDolje="Čuče: Ivan Večerić, Bruno Kopun, Stefan Knežević, Juraj Hrdas, Matija Piškor (kap.), Matija Librić, Robert Gonđić, Nikola Cvetković , Filip Bojić, Ivan Marko Pišta."
          />

          <Calendar
            year="2014"
            text="U takmičarskoj godni 2013/2014 seniorska momčad nastupala je u Kupu ZNS-a. Do 5. kola Kupa ZNS-a pobjeđivane su momčadi koje se takmiče u I. i II. Ligi ZNS-a. U 5. kolu Kupa ZNS-a seniorska momčad NK Podsused-a izgubila je od NK „Dubrava“ koja se takmiči tri ranga iznad ranga u kojem se takmiči NK Podsused (momčad III. Hrvatske nogometne lige). Momčadi omladinskog uzrasta u takmičarskoj 2012/2013. osvajali mjesta u sredini tablice. U takmičarskoj godini 2013/2014. Klub se takmiči s četiri momčadi omladinskog pogona (zagići, limaći, mlađi pioniri i pioniri) te seniorskom momčadi. Za istaći je momčad zagića koja učestalo ostvaruje vrlo dobre rezultate, a najzapaženiji rezultat ostvaren je na Zimskoj ligi odigranoj na Velesajmu (početak 2014.) gdje je osvojeno 3. mjesto (od 12 momčadi), a igrač zagića NK Podsused-a Jakov Kapuđija, proglašen je najboljim igračem lige."
          />
          <CalendarImage img={Zagici2014} name="Zagići NK Podsused 2013/2014" />
          <CalendarImage img={Limaci2014} name="Limaći NK Podsused 2013/2014" />
          <CalendarImage
            img={Pioniri2014}
            name="Pioniri NK Podsused 2013/2014"
            igraciGore="Stoje: trener Stefan Knežević, Matija Strbad (C), Enis Beriša, Kristijan Županić, Nikola Vukelić, Andre Niall Bračun, Iva Tomkić, Patrik Kranjec, Jurica Stanišak."
            igraciDolje="Sjede: Elvis Beriša, Elvin Beriša, Manuel Cetinić, Teo Ivanuš, Antonio Ciglević, Maksimilian Kokić, Borna Marciuš, Alen Stjepanović."
          />
          <Calendar
            year="2020"
            text="U suradnji s Gradskom četvrti Podsused - Vrapče započinju radovi na infrastrukturi i okolišu Kluba."
          />
          <CalendarImage img={Klub2021} name="Radovi u klubu 2020" />
          <Calendar
            year="2020/21"
            text="Skupina mladih igrača poniklih u Klubu ili kvartu, uz potporu Uprave užurbano rade na okupljanju seniorskog tima NK Podsuseda nakon niza godina tijekom kojih NK Podsused nije imao seniorski sastav. Entuzijazma i pozitivne energije nije nedostajalo pa se nakon rješavanja administrativnih i financijskih izazova ova skupina mladih ljudi započela pisanje nove povijesti Kluba i našeg kvarta. Ekipa seniora je zaigrala na jeseni i na taj način doprinijela obilježavanju 100-godišnjice Kluba."
          />
          <CalendarImage img={Seniori2020} name="Seniori 2020/2021" />
          <Calendar year="2021" text="100 GODINA KLUBA " text2="" />
          <CalendarImage img={StoGodina} name="100 Godina Kluba" />
          <Calendar year="2022" text="" />
          <Calendar year="2023" text="" />
          <Calendar year="2024" text="" />
        </div>
      </div>
    </>
  );
}

function Calendar(props) {
  return (
    <div className="calendar-box">
      <h4>{props.year}</h4>
      <span>~</span>
      <p>{props.text}</p>
      <p>{props.text2}</p>
    </div>
  );
}

function CalendarImage(props) {
  return (
    <div className="calendar-img">
      <img src={props.img} alt={props.name} loading="lazy" />
      <p>{props.name}</p>
      <span>{props.igraciGore}</span>
      <span>{props.igraciDolje}</span>
    </div>
  );
}
