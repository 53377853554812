import React, { useEffect, useState } from "react";
import Main from "../Home/Main";

// Images
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

// import PodsusedGalerija from "../../../Assets/Images/Galerija/PodsusedGalerija.jpg";
import Galerija1 from "../../../Assets/Images/Galerija/Galerija1.jpg";
import Galerija2 from "../../../Assets/Images/Galerija/Galerija2.jpg";
import Galerija3 from "../../../Assets/Images/Galerija/Galerija3.jpg";
import Galerija4 from "../../../Assets/Images/Galerija/Galerija4.jpg";
import Galerija5 from "../../../Assets/Images/Galerija/Galerija5.jpg";
// import Galerija6 from "../../../Assets/Images/Galerija/Galerija6.jpg";
// import Galerija7 from "../../../Assets/Images/Galerija/Galerija7.jpg";
import Galerija8 from "../../../Assets/Images/Galerija/Galerija8.jpg";
import Galerija9 from "../../../Assets/Images/Galerija/Galerija9.jpg";
import Galerija10 from "../../../Assets/Images/Galerija/Galerija10.jpg";
import Galerija11 from "../../../Assets/Images/Galerija/Galerija11.jpg";
// import Galerija12 from "../../../Assets/Images/Galerija/Galerija12.jpg";
import Galerija13 from "../../../Assets/Images/Galerija/Galerija13.jpg";

/* ********************************* */
export default function Gallery() {
  const [showImage, setShowImage] = useState("");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showAllImages, setShowAllImages] = useState(false);

  const openImage = (imageSrc, index) => {
    setShowImage(imageSrc);
    setCurrentImageIndex(index);
  };

  const closeImage = () => {
    setShowImage("");
  };

  const prevImage = (e) => {
    e.stopPropagation(); // Stop event propagation to prevent closing the image
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextImage = (e) => {
    e.stopPropagation(); // Stop event propagation to prevent closing the image
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const toggleShowAllImages = () => {
    setShowAllImages(!showAllImages);
  };

  useEffect(() => {
    document.title = "NK Podsused | Galerija";

    // Event listener for keyboard navigation
    const handleKeyPress = (e) => {
      if (showImage) {
        if (e.key === "ArrowLeft") {
          prevImage(e);
        } else if (e.key === "ArrowRight") {
          nextImage(e);
        } else if (e.key === "Escape") {
          closeImage();
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showImage]);

  // Combine images with authors
  const galleryItems = [
    { image: Galerija13, author: "Mala Mlaka" },
    /*     { image: Galerija12, author: "Mala Mlaka" }, */
    { image: Galerija11, author: "Mala Mlaka" },
    { image: Galerija10, author: "Mala Mlaka" },
    { image: Galerija9, author: "Mala Mlaka" },
    /*     { image: Galerija7, author: "Mala Mlaka" }, */
    /*  { image: Galerija6, author: "Podsused" }, */
    { image: Galerija1, author: "Marko Šivak" },
    { image: Galerija2, author: "Marko Šivak" },
    { image: Galerija4, author: "Marko Šivak" },
    { image: Galerija5, author: "Marko Šivak" },
    { image: Galerija3, author: "NK Podsused" },
    { image: Galerija8, author: "Podsused" },
  ];

  const images = showAllImages
    ? galleryItems.map((item) => item.image)
    : galleryItems.slice(0, 9).map((item) => item.image);

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="GALERIJA"
      />
      <div className="section">
        <div className="gallery">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt="Galerija"
              onClick={() => openImage(image, index)}
              loading="lazy"
            />
          ))}
        </div>
        {!showAllImages && (
          <button className="btn more-btn" onClick={toggleShowAllImages}>
            Cijela galerija
          </button>
        )}
        {showImage && (
          <div className="overlay" onClick={closeImage}>
            <div id="popup">
              <span id="close" onClick={closeImage}>
                &times;
              </span>
              <button className="prev popup-btn" onClick={(e) => prevImage(e)}>
                &#10094;
              </button>
              <button className="next popup-btn" onClick={(e) => nextImage(e)}>
                &#10095;
              </button>
              <img
                id="popupImage"
                src={galleryItems[currentImageIndex].image}
                alt="Show"
                loading="lazy"
              />
              <span className="img-author">
                FOTO: {galleryItems[currentImageIndex].author}
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
