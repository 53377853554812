import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

import Main from "../Home/Main";
import { useEffect } from "react";

export default function Donations() {
  useEffect(() => {
    document.title = "NK Podsused | Donacije";
  }, []);
  return (
    <>
      {" "}
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="DONACIJE"
      />
      <div className="section">
        <h3>Želis podržati svoj klub? </h3>
        <p>Uplatom na QR kod možete donirati sredstva, kako bi...</p>
      </div>
    </>
  );
}
