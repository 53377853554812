import Main from "./Main";
import { useEffect } from "react";
// Images
import RasporedTreningaBg from "../../../Assets/Images/Ostalo/Trening.jpg";

export default function RasporedTreninga() {
  useEffect(() => {
    document.title = "NK Podsused | Raspored treninga";
  }, []);
  return (
    <>
      <Main
        desktopBackground={RasporedTreningaBg}
        phoneBackground={RasporedTreningaBg}
        title="RASPORED TRENINGA"
      />

      <div className="section raspored-treninga">
        <h4>TRENINZI SU PODLOŽNI PROMJENAMA!</h4>
        <Trening
          momcad="LIMAČI"
          dan1="UTO, SRI"
          sati1="18:30"
          dan2="ČET, PET"
          sati2="18:30"
          trener="/"
          number="/"
        />

        <Trening
          momcad="ZAGIĆI"
          dan1="UTO"
          sati1="18:30"
          dan2="PET"
          sati2="18:30"
          trener="/"
          number="/"
        />

        <Trening
          momcad="ML. PIONIRI"
          dan1="SRI, ČET"
          sati1="19:30"
          dan2="PET"
          sati2="19:30"
          trener="/"
          number="/"
        />

        <Trening
          momcad="PIONIRI"
          dan1="SRI, ČET"
          sati1="19:30"
          dan2="PET"
          sati2="19:30"
          trener="/"
          number="/"
        />

        <Trening
          momcad="KADETI"
          dan1="UTO, SRI"
          sati1="21:00"
          dan2="ČET, PET"
          sati2="21:00"
          trener="/"
          number="/"
        />

        <Trening
          momcad="SENIORI"
          dan1="UTORAK"
          sati1="19:30"
          dan2="PET"
          sati2="19:30"
          trener="Marko Žabić"
          number="/"
        />
      </div>
    </>
  );
}

function Trening(props) {
  return (
    <div className="trening">
      <div className="trening-grid">
        <h2>{props.momcad}</h2>
        <div className="trening-box">
          <h4>{props.dan1}</h4>
          <p>{props.sati1}</p>
        </div>
        <div className="trening-box">
          <h4>{props.dan2}</h4>
          <p>{props.sati2}</p>
        </div>
      </div>
      <div className="trening-info">
        <h5>TRENER: {props.trener}</h5>
        <a href="tel={props.number}">
          <i className="fi fi-rs-phone-flip"></i> {props.number}
        </a>
      </div>
    </div>
  );
}
