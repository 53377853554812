import Main from "../Home/Main";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
// Images
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

export default function Contact() {
  useEffect(() => {
    document.title = "NK Podsused | Kontakt";
  }, []);
  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="KONTAKT"
      />
      <ContactInfo />
    </>
  );
}

function ContactInfo() {
  const [count, setCount] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (count < 186) {
        setCount((prevCount) => prevCount + 1);
      } else {
        clearInterval(interval);
      }
    }, 12);
    return () => clearInterval(interval);
  }, [count]);
  return (
    <>
      <div className="section contact">
        <h2>NK PODSUSED</h2>
        <h4>Godina osnutka</h4>
        <p>1921</p>
        <h4>Broj registriranih igrača</h4>
        <div className="members-amout">
          <div className="amout-line">{count}</div>
        </div>
        <h4>Predsjednik</h4>
        <p>Nikola Lipovac</p>
        <a href="tel:099 3882 704" className="links">
          +385 99 3882 704
        </a>
        <h4>Tajnik</h4>
        <p>Mario Berend</p>
        <a href="tel:098 738 506" className="links">
          +385 98 738 506
        </a>
        <ul className="contact-links">
          <li></li>
          <li>
            <Link
              className="links"
              to="https://maps.app.goo.gl/67Kd1onNPYh3ZJxu6"
            >
              {" "}
              <i className="fi fi-rs-marker"></i>Kupališni put 1, 10000, Zagreb
            </Link>
          </li>
          <li>
            <a
              href="mailto:podsused.nk@gmail.com"
              className="links"
              to="podsused.nk@gmail.com"
            >
              <i className="fi fi-rs-at"></i> podsused.nk@gmail.com
            </a>
          </li>
        </ul>
        <p>IBAN: HR8124070001100670399</p>
      </div>
    </>
  );
}
