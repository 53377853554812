import Standings from "./Standings";
import Matches from "./Matches";
// import Statistics from "./Statistics";

// Images
/* 
import Turnir from "../../../Assets/Images/Ostalo/Turnir.jpg";
import NKSloboda1966 from "../../../Assets/Images/Klub/NK-Sloboda-1966.jpg"; */
// import PodsusedNovosti from "../../../Assets/Images/Ostalo/Podsused-Novosti.jpg";
// import SaTekme from "../../../Assets/Images/Ostalo/SaTekme.jpg";
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
// import QuizBg from "../../../Assets/Images/Ostalo/Quiz-Bg.jpg";

// Klubovi images
import NKPodsused from "../../../Assets/Images/Klubovi/NK-Podsused.png";

// import NKCroatia98 from "../../../Assets/Images/Klubovi/NK-Croatia98.png";
//import NKCehi from "../../../Assets/Images/Klubovi/NK-Cehi.png";
import NKCiroAcademy from "../../../Assets/Images/Klubovi/NK-Ciro-Academy.png";
// import NKCulinec from "../../../Assets/Images/Klubovi/NK-Culinec.png";
// import NKDragonozec from "../../../Assets/Images/Klubovi/NK-Dragonozec.png";
// import NKGavran from "../../../Assets/Images/Klubovi/NK-Gavran.png";
// import NKHrasce from "../../../Assets/Images/Klubovi/NK-Hrasce.png";
// import NKIvanjaReka from "../../../Assets/Images/Klubovi/NK-Ivanja-Reka.png";
import NKSloga from "../../../Assets/Images/Klubovi/NK-Sloga.png";
// import NKStrmec from "../../../Assets/Images/Klubovi/NK-Strmec.png";
// import NKZagreb041 from "../../../Assets/Images/Klubovi/NK-Zagreb041.png";

// Components
import Main from "./Main";
import Dresovi from "./Dresovi";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import News from "../News/News";
import newsData from "../News/NewsData";
import MemberHome from "./MemberHome";
// import QuizBox from "../Novosti/QuizBox";

export default function Home() {
  useEffect(() => {
    document.title = "NK Podsused";
  }, []);
  const handleClick = (path) => {
    window.scrollTo(0, 0);
  };

  const sortedNews = [...newsData].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  // Get the last two news items
  const latestNews = sortedNews.slice(0, 2);

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title=""
      />
      <div className="section">
        <div className="season-matches-top">
          {/* 3. KOLO */}
          <Matches
            id="match3"
            match="3. KOLO"
            homeTeam="NK Sloga Zagreb"
            homeTeamLogo={NKSloga}
            awayTeam="NK Podsused"
            awayTeamLogo={NKPodsused}
            result=" - "
            date="22.09.2024"
            time="11:00"
            location="NK SLOGA"
          />
          {/* 2. KOLO */}
          <Matches
            id="match1"
            match="2. KOLO"
            homeTeam="NK Podsused"
            homeTeamLogo={NKPodsused}
            awayTeam="NK Čiro Academy"
            awayTeamLogo={NKCiroAcademy}
            result="5 - 3"
            date="14.09.2024"
            time="17:00"
            location="NK PODSUSED"
            players={[
              { name: "G. BAŠIĆ", pos: "GK" },
              { name: "M. VODOPIJA", pos: "LB" },
              { name: "D. MIHOKOVIĆ", pos: "RCB" },
              { name: "R. BUDISELIĆ", pos: "LCB" },
              { name: "K. MINKOVIĆ", pos: "RB" },
              { name: "C | J. GOTAL", pos: "CM" },
              { name: "K. MIHELČIĆ", pos: "LCM" },
              { name: "S. MILOŠ", pos: "RCM" },
              { name: "J. JAMBREK", pos: "LW" },
              { name: "J. MESIĆ", pos: "ST" },
              { name: "F. ĐURČIĆ", pos: "RW" },
            ]}
            benchPlayers={[
              { name: "T. IVANUŠ" },
              { name: "A. BORAS" },
              { name: "L. MOGULJAK" },
              { name: "L. IVČIĆ" },
              { name: "K. BORKO" },
              { name: "M. SEDMAK" },
            ]}
            details={[
              { home: "J. Mesić 4'", HE: "goal" },
              { home: "", away: "18' F. Hodak", AE: "goal" },
              { home: "K. Mihelčić 23'", HE: "goal" },
              { home: "S. Miloš 29'", HE: "goal" },
              { home: "S. Miloš 41'", HE: "goal" },
              { home: "", away: "51' F. Hodak", AE: "goal" },
              { home: "", away: "78' M. Prnjak", AE: "goal" },
              { home: "F. Đuričić' 90'", HE: "goal" },
            ]}
          />

          <Link
            to="/utakmice"
            className="btn utakmice-btn"
            onClick={() => handleClick("/")}
          >
            Ostale utakmice
          </Link>
        </div>

        <Dresovi />
        {/* *****NOVOSTI***** */}
        <h2>NOVOSTI</h2>
        <div className="grid-2">
          {latestNews.map((newsItem) => (
            <News key={newsItem.id} slug={newsItem.slug} />
          ))}
        </div>
        <div className="news-btn">
          <Link to="/novosti" className="btn" onClick={() => handleClick("/")}>
            Ostale novosti...
          </Link>
        </div>
        <div className="light-border"></div>
        <h2>SEZONA 2024/2025</h2>
        <h5 className="league-title">2. ZAGREBAČKA LIGA</h5>
        <Standings />

        {/*        <div className="light-border"></div>
        <h2>NAJBOLJI STRIJELCI PODSUSEDA</h2>
        <h5 className="league-title">SEZONA 2023/2024</h5> */}
        {/*   <Statistics /> */}
        <div className="light-border"></div>
        <MemberHome />
      </div>
    </>
  );
}

/*     <Link to="/kviz" onClick={() => handleClick("/")}>
            <QuizBox
              image={QuizBg}
              subtitle="NK Podsused kviz"
              title="Koliko dobro znate o Nogometom Klubu Podsused..."
            />
          </Link> */
