import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import React, { useEffect, useRef } from "react";

// Klubovi images

// Components
import Matches from "../Home/Matches";
import Main from "../Home/Main";

export default function Season() {
  const scrollToMatch = (matchId) => {
    const matchElement = document.getElementById(matchId);

    if (matchElement) {
      const scrollYOffset =
        matchElement.getBoundingClientRect().top +
        window.pageYOffset -
        (window.innerHeight - matchElement.clientHeight) / 2;

      window.scrollTo({ top: scrollYOffset, behavior: "smooth" });
    }
  };

  useEffect(() => {
    document.title = "NK Podsused | Sezona 2023/2024";
  }, []);

  const selectRef = useRef(null);

  const handleSelectChange = () => {
    const selectedOption = selectRef.current.value;
    scrollToMatch(selectedOption);
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
      />
      <div className="section">
        <select
          ref={selectRef}
          className="season-search"
          onChange={handleSelectChange}
        >
          <option value="Seniori">SENIORI</option>
          <option value="Kadeti">KADETI</option>
          <option value="Pioniri2">PIONIRI 2</option>
          <option value="Pioniri1">PIONIRI 1</option>
          <option value="MLPioniri2">ML. PIONIRI 2</option>
          <option value="MlPioniri1">ML. PIONIRI 1</option>
          <option value="Limaci">LIMAČI</option>
          <option value="Zagici">ZAGIĆI</option>
        </select>
        <div className="season-matches-top">
          <h2>SEZONA 2024/2025</h2>

          <h3>SENIORI</h3>
          <Matches
            id="Seniori"
            match="SENIORI"
            homeTeam="NK Dragonožec"
            awayTeam="NK Podsused"
            result="2:1"
            location=" NK Dragonožec "
          />
          <div className="season-border"></div>

          <h3>KADETI</h3>
          <Matches
            id="Kadeti"
            match="KADETI 1.ZNL"
            homeTeam="NK Podsused"
            awayTeam="NK Trešnjevka"
            result="3:2"
            location=" NK Podsused "
          />
          <div className="season-border"></div>

          <Matches
            id="Kadeti"
            match="KADETI 2.ZNL"
            homeTeam="NK Šparta"
            awayTeam="NK Podsused"
            result="2:4"
            location=" NK Šparta "
          />
          <div className="season-border"></div>

          <h3>PIONIRI</h3>
          <Matches
            id="Pioniri1"
            match="PIONIRI 1.ZNL"
            homeTeam="NK Ponikve"
            awayTeam="NK Podsused"
            result="2:3"
            location=" NK Ponikve "
          />
          <div className="season-border"></div>

          <Matches
            id="Pioniri2"
            match="PIONIRI 3.ZNL"
            homeTeam="NK Podsused"
            awayTeam="NK Most"
            result="3:1"
            location=" NK Podsused "
          />
          <div className="season-border"></div>

          <h3>MLAĐI PIONIRI</h3>
          <Matches
            id="MlPioniri1"
            match="MLAĐI PIONIRI 1.ZNL"
            homeTeam="NK Ponikve"
            awayTeam="NK Podsused"
            result="2:0"
            location=" NK Ponikve "
          />
          <div className="season-border"></div>

          <h3>LIMAČI</h3>
          <Matches
            id="Limaci"
            match="LIMAČI 3.D ZNL"
            homeTeam="NK Podsused"
            awayTeam="NK Mladost Buzin"
            result="0:2"
            location="NK Podsused "
          />
          <div className="season-border"></div>

          <h3>ZAGIĆI</h3>
          <Matches
            id="Zagici"
            match="ZAGIĆI 3.D ZNL"
            homeTeam="NK Podsused"
            awayTeam="NK Mladost Buzin"
            result="18:0"
            location=" NK Podsused "
          />
        </div>
      </div>
    </>
  );
}
