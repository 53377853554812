import React, { useEffect } from "react";
// Images
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

// Klubovi images
import NKPodsused from "../../../Assets/Images/Klubovi/NK-Podsused.png";
import NKCroatia98 from "../../../Assets/Images/Klubovi/NK-Croatia98.png";
import NKCehi from "../../../Assets/Images/Klubovi/NK-Cehi.png";
import NKCiroAcademy from "../../../Assets/Images/Klubovi/NK-Ciro-Academy.png";
import NKCulinec from "../../../Assets/Images/Klubovi/NK-Culinec.png";
import NKDragonozec from "../../../Assets/Images/Klubovi/NK-Dragonozec.png";
import NKGavran from "../../../Assets/Images/Klubovi/NK-Gavran.png";
import NKHrasce from "../../../Assets/Images/Klubovi/NK-Hrasce.png";
import NKIvanjaReka from "../../../Assets/Images/Klubovi/NK-Ivanja-Reka.png";
import NKSloga from "../../../Assets/Images/Klubovi/NK-Sloga.png";
import NKStrmec from "../../../Assets/Images/Klubovi/NK-Strmec.png";
import NKZagreb041 from "../../../Assets/Images/Klubovi/NK-Zagreb041.png";

// Components
import Matches from "../Home/Matches";
import Main from "../Home/Main";

export default function Season() {
  useEffect(() => {
    document.title = "NK Podsused | Seniori Sezona 2023/2024";
  }, []);
  const scrollToMatch = (kolo) => {
    const matchId = `match${kolo}`;
    const matchElement = document.getElementById(matchId);

    if (matchElement) {
      const scrollYOffset =
        matchElement.getBoundingClientRect().top -
        (window.innerHeight - matchElement.clientHeight) / 2;

      window.scrollBy({ top: scrollYOffset, behavior: "smooth" });
    }
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
      />
      <div className="section">
        <select
          className="season-search"
          onChange={(e) => scrollToMatch(parseInt(e.target.value))}
        >
          <option value="11">11. KOLO</option>
          <option value="10">10. KOLO</option>
          <option value="9">9. KOLO</option>
          <option value="8">8. KOLO</option>
          <option value="7">7. KOLO</option>
          <option value="6">6. KOLO</option>
          <option value="5">5. KOLO</option>
          <option value="4">4. KOLO</option>
          <option value="3">3. KOLO</option>
          <option value="2">2. KOLO</option>
          <option value="1" selected>
            1. KOLO
          </option>
        </select>
        <div className="season-matches-top">
          {/* 1. KOLO */}
          <Matches
            id="match1"
            match="1. KOLO"
            homeTeam="NK Dragonožec"
            homeTeamLogo={NKDragonozec}
            awayTeam="NK Podsused"
            awayTeamLogo={NKPodsused}
            result="2 - 1"
            date="08.09.2024"
            time="17:30"
            location="NK DRAGONOŽEC"
            players={[
              { name: "G. BAŠIĆ", pos: "GK" },
              { name: "M. VODOPIJA", pos: "LB" },
              { name: "D. MIHOKOVIĆ", pos: "RCB" },
              { name: "M. PERKOVIĆ", pos: "LCB" },
              { name: "R. BUDISELIĆ", pos: "RB" },
              { name: "C | J. GOTAL", pos: "RCM" },
              { name: "K. MIHELČIĆ", pos: "LCM" },
              { name: "F. ĐURČIĆ", pos: "CM" },
              { name: "J. JAMBREK", pos: "ST" },
              { name: "J. MESIĆ", pos: "LW" },
              { name: "L. NERAT", pos: "RW" },
            ]}
            benchPlayers={[
              { name: "A. BORAS" },
              { name: "K. MINKOVIĆ" },
              { name: "L. MOGULJAK" },
              { name: "M. STUNJA" },
              { name: "J. JURIĆ" },
              { name: "K. BORKO" },
            ]}
            details={[
              { home: "", away: "20' M. Perković", AE: "yellow" },
              { home: "", away: "33' J. Jurić", AE: "goal" },
              { home: "H. Kolarić 37'", HE: "goal" },
              { home: "", away: "80' J. Jurić", AE: "red" },
              { home: "", away: "83' M. Stunja", AE: "yellow" },
              { home: "H. Kolarić 84'", HE: "goal" },
            ]}
          />
          {/* 2. KOLO */}
          {/* 2. KOLO */}
          <Matches
            id="match1"
            match="2. KOLO"
            homeTeam="NK Podsused"
            homeTeamLogo={NKPodsused}
            awayTeam="NK Čiro Academy"
            awayTeamLogo={NKCiroAcademy}
            result="5 - 3"
            date="14.09.2024"
            time="17:00"
            location="NK PODSUSED"
            players={[
              { name: "G. BAŠIĆ", pos: "GK" },
              { name: "M. VODOPIJA", pos: "LB" },
              { name: "D. MIHOKOVIĆ", pos: "RCB" },
              { name: "R. BUDISELIĆ", pos: "LCB" },
              { name: "K. MINKOVIĆ", pos: "RB" },
              { name: "C | J. GOTAL", pos: "CM" },
              { name: "K. MIHELČIĆ", pos: "LCM" },
              { name: "S. MILOŠ", pos: "RCM" },
              { name: "J. JAMBREK", pos: "LW" },
              { name: "J. MESIĆ", pos: "ST" },
              { name: "F. ĐURČIĆ", pos: "RW" },
            ]}
            benchPlayers={[
              { name: "T. IVANUŠ" },
              { name: "A. BORAS" },
              { name: "L. MOGULJAK" },
              { name: "L. IVČIĆ" },
              { name: "K. BORKO" },
              { name: "M. SEDMAK" },
            ]}
            details={[
              { home: "J. Mesić 4'", HE: "goal" },
              { home: "", away: "18' F. Hodak", AE: "goal" },
              { home: "K. Mihelčić 23'", HE: "goal" },
              { home: "S. Miloš 29'", HE: "goal" },
              { home: "S. Miloš 41'", HE: "goal" },
              { home: "", away: "51' F. Hodak", AE: "goal" },
              { home: "", away: "78' M. Prnjak", AE: "goal" },
              { home: "F. Đuričić' 90'", HE: "goal" },
            ]}
          />
          {/* 3. KOLO */}
          <Matches
            id="match3"
            match="3. KOLO"
            homeTeam="NK Sloga Zagreb"
            homeTeamLogo={NKSloga}
            awayTeam="NK Podsused"
            awayTeamLogo={NKPodsused}
            result=" - "
            date="22.09.2024"
            time="11:00"
            location="NK SLOGA"
          />
          {/* 4. KOLO */}
          <Matches
            id="match4"
            match="4. KOLO"
            homeTeam="NK Podsused"
            homeTeamLogo={NKPodsused}
            awayTeam="NK Croatia 98"
            awayTeamLogo={NKCroatia98}
            result=" - "
            date="28.09.2024"
            time="16:30"
            location="NK PODSUSED"
          />
          {/* 5. KOLO */}
          <Matches
            id="match5"
            match="5. KOLO"
            homeTeam="NK Čehi"
            homeTeamLogo={NKCehi}
            awayTeam="NK Podsused"
            awayTeamLogo={NKPodsused}
            result=" - "
            date="06.10.2024"
            time="16:00"
            location="NK ČEHI"
          />
          {/* 6. KOLO */}
          <Matches
            id="match6"
            match="6. KOLO"
            homeTeam="NK Podsused"
            homeTeamLogo={NKPodsused}
            awayTeam="NK Čulinec"
            awayTeamLogo={NKCulinec}
            result=" - "
            date="12.10.2024"
            time="16:00"
            location="NK PODSUSED"
          />
          {/* 7. KOLO */}
          <Matches
            id="match7"
            match="7. KOLO"
            homeTeam="NK Hrašće"
            homeTeamLogo={NKHrasce}
            awayTeam="NK Podsused"
            awayTeamLogo={NKPodsused}
            result=" - "
            date="20.10.2024"
            time="15:30"
            location="NK HRAŠĆE"
          />
          {/* 8. KOLO */}
          <Matches
            id="match8"
            match="8. KOLO"
            homeTeam="NK Gavran"
            homeTeamLogo={NKGavran}
            awayTeam="NK Podsused"
            awayTeamLogo={NKPodsused}
            result=" - "
            date="27.10.2024"
            time="14:30"
            location="NK GAVRAN"
          />
          {/* 9. KOLO */}
          <Matches
            id="match9"
            match="9. KOLO"
            homeTeam="NK Podsused"
            homeTeamLogo={NKPodsused}
            awayTeam="NK Ivanja Reka"
            awayTeamLogo={NKIvanjaReka}
            result=" - "
            date="02.11.2024"
            time="14:30"
            location="NK PODSUSED"
          />
          {/* 10. KOLO */}
          <Matches
            id="match10"
            match="10. KOLO"
            homeTeam="NK Zagreb 041"
            homeTeamLogo={NKZagreb041}
            awayTeam="NK Podsused"
            awayTeamLogo={NKPodsused}
            result=" - "
            date="09.11.2024"
            time="14:00"
            location="NK ZAGREB 041"
          />
          {/* 11. KOLO */}
          <Matches
            id="match11"
            match="11. KOLO"
            homeTeam="NK Podsused"
            homeTeamLogo={NKPodsused}
            awayTeam="NK Strmec Zagreb"
            awayTeamLogo={NKStrmec}
            result=" - "
            date="16.11.2024"
            time="14:00"
            location="NK PODSUSED"
          />
        </div>
      </div>
    </>
  );
}

/*     players={[
              { name: "G. BAŠIĆ", pos: "GK" },
              { name: "M. STUNJA", pos: "LB" },
              { name: "D. MIHOKOVIĆ", pos: "RCB" },
              { name: "R. BUDISELIĆ", pos: "RB" },
              { name: "M. PERKOVIĆ", pos: "LCB" },
              { name: "C | J. GOTAL", pos: "RCM" },
              { name: "K. MIHELČIĆ", pos: "LCM" },
              { name: "S. MILOŠ", pos: "CM" },
              { name: "M. BIRUŠ", pos: "ST" },
              { name: "J. MESIĆ", pos: "RW" },
              { name: "J. JAMBREK", pos: "LW" },
            ]}
            benchPlayers={[
              { name: "T. IVANUŠ" },
              { name: "M. SEDMAK" },
              { name: "J. GLIHA" },
              { name: "F. BORŠIĆ" },
              { name: "K. MINKOVIĆ" },
            ]}
            details={[
              { home: "", away: "23' M. Šokčević", AE: "goal" },
              { home: "D. Mihoković 32'", HE: "goal" },
              { home: "", away: "65' J. Šupe", AE: "goal" },

              { home: "", away: "85' J. Šupe", AE: "goal" },
            ]} */
