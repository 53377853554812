import Main from "../Home/Main";
import React, { useEffect, useState } from "react";
// Images
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import TotalSports from "../../../Assets/Images/Sponzori/Total-Sports.png";
// Oprema odjeća slike

import DomaćiDresPrednji from "../../../Assets/Images/Oprema/Domaći-prednji.png";
import DomaćiDresIza from "../../../Assets/Images/Oprema/Domaći-iza.png";
import GostujućiDresPrednji from "../../../Assets/Images/Oprema/Gostujući-prednja.png";
import GostujućiDresIza from "../../../Assets/Images/Oprema/Gostujući-iza.png";
import GolmanskiCrveniPrednji from "../../../Assets/Images/Oprema/Golmanski-crveni-prednji.png";
import GolmanskiCrveniIza from "../../../Assets/Images/Oprema/Golmanski-crveni-iza.png";
import GolmanskiŽutiPrednji from "../../../Assets/Images/Oprema/Golmanski-žuti-prednji.png";
import GolmanskiŽutiIza from "../../../Assets/Images/Oprema/Golmanski-žuti-iza.png";

import DuksaPrednja from "../../../Assets/Images/Oprema/Duksa-prednja.png";
import DuksaIza from "../../../Assets/Images/Oprema/Duksa-iza.png";
import JaknaPrednja from "../../../Assets/Images/Oprema/Jakna-prednja.png";
import JaknaIza from "../../../Assets/Images/Oprema/Jakna-iza.png";
import ŠuškavacPrednji from "../../../Assets/Images/Oprema/Šuškavac-prednji.png";
import ŠuškavacIza from "../../../Assets/Images/Oprema/Šuškavac-iza.png";
import SvečanaPrednja from "../../../Assets/Images/Oprema/Svečana-prednja.png";
import SvečanaIza from "../../../Assets/Images/Oprema/Svečana-iza.png";
import TrenirkaBočno from "../../../Assets/Images/Oprema/Trenirka-bočno.png";
import TrenirkaPrednja from "../../../Assets/Images/Oprema/Trenirka-prednja.png";
import RadnaTrenirkaPrednja from "../../../Assets/Images/Oprema/Radna-trenirka-prednja.png";
import RadnaTrenirkaIza from "../../../Assets/Images/Oprema/Radna-trenirka-iza.png";
import PoloIza from "../../../Assets/Images/Oprema/Polo-iza.png";
import PoloPrednja from "../../../Assets/Images/Oprema/Polo-prednja.png";
import MajicaPrednja from "../../../Assets/Images/Oprema/Majica-prednja.png";
import MajicaIza from "../../../Assets/Images/Oprema/Majica-iza.png";
import MajicaBijela from "../../../Assets/Images/Oprema/Majica-bijela.png";
import KratkeHlačeBočna from "../../../Assets/Images/Oprema/Kratke-hlače-bočna.png";
import KratkeHlačePrednja from "../../../Assets/Images/Oprema/Kratke-hlače-prednja.png";
// Paketi
import TreningPaket from "../../../Assets/Images/Oprema/Trening-paket.png";
import OsnovniPaket from "../../../Assets/Images/Oprema/Osnovni-paket.png";
import PodsusedPaket from "../../../Assets/Images/Oprema/Podsused-paket.png";
import PodsusedTreningPaket from "../../../Assets/Images/Oprema/Podsused-trening-paket.png";
// Ostalo oprema
import Šal from "../../../Assets/Images/Oprema/Šal.png";
import Kapa from "../../../Assets/Images/Oprema/Kapa.png";
import Vrečica from "../../../Assets/Images/Oprema/Vrečica.png";
import Ruksak from "../../../Assets/Images/Oprema/Ruksak.png";
import ZastavicaMala from "../../../Assets/Images/Oprema/Zastavica-mala.png";
import ZastavicaVelika from "../../../Assets/Images/Oprema/Zastavica-velika.png";
import { Link } from "react-router-dom";

/* ********************************* */

export default function Oprema() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImages, setModalImages] = useState([]);
  const [modalTitles, setModalTitles] = useState([]); // State za naslove

  const handleOpenModal = (images, titles) => {
    setModalImages(images);
    setModalTitles(titles); // Postavi naslove
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    document.title = "NK Podsused | Oprema";
  }, []);

  const handleClick = (path) => {
    window.scrollTo(0, 0);
  };

  const preventContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="OPREMA"
      />
      <div className="section oprema">
        <Link className="kit-button" to="https://www.total-sport.hr/">
          <img className="slider-img" src={TotalSports} alt="Total Sports" />
        </Link>
        <h3>DRESOVI</h3>
        <div className="oprema-box">
          {/* Domaći dres */}
          <div
            className="kit-box"
            onClick={() =>
              handleOpenModal(
                [DomaćiDresPrednji, DomaćiDresIza],
                ["Domaći dres", "Domaći dres"]
              )
            }
          >
            <img
              src={DomaćiDresPrednji}
              alt="Domaći dres prednja strana"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Domaći dres</h4>
            <p>Dresovi</p>
          </div>

          {/* Gostujući dres */}
          <div
            className="kit-box"
            onClick={() =>
              handleOpenModal(
                [GostujućiDresPrednji, GostujućiDresIza],
                ["Gostujući dres", "Gostujući dres"]
              )
            }
          >
            <img
              src={GostujućiDresPrednji}
              alt="Gostujući dres prednja strana"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Gostujući dres</h4>
            <p>Dresovi</p>
          </div>
          {/* Golmanski Crveni */}
          <div
            className="kit-box"
            onClick={() =>
              handleOpenModal(
                [GolmanskiCrveniPrednji, GolmanskiCrveniIza],
                ["Golmanski dres, crveni", "Golmanski dres, crveni"]
              )
            }
          >
            <img
              src={GolmanskiCrveniPrednji}
              alt="Golmanski dres crveni"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Golmanski dres, crveni</h4>
            <p>Dresovi</p>
          </div>
          {/* Golmanski Žuti */}
          <div
            className="kit-box"
            onClick={() =>
              handleOpenModal(
                [GolmanskiŽutiPrednji, GolmanskiŽutiIza],
                ["Golmanski dres, žuti", "Golmanski dres, žuti"]
              )
            }
          >
            <img
              src={GolmanskiŽutiPrednji}
              alt="Golmanski dres crveni"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Golmanski dres, žuti</h4>
            <p>Dresovi</p>
          </div>
        </div>
        {/* OSTALA OPREMA */}
        <h3>DODATNA OPREMA</h3>
        <div className="oprema-box">
          {/* BIJELA MAJICA */}
          <div
            className="kit-box"
            onClick={() =>
              handleOpenModal([MajicaBijela], ["Trening majica - bijela"])
            }
          >
            <img
              src={MajicaBijela}
              alt="Trening majica - bijela"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Trening majica - bijela</h4>
            <p>Dodatna oprema</p>
          </div>
          {/* PLAVA MAJICA */}
          <div
            className="kit-box"
            onClick={() =>
              handleOpenModal(
                [MajicaPrednja, MajicaIza],
                ["Trening majica - plava", "Trening majica - plava"]
              )
            }
          >
            <img
              src={MajicaPrednja}
              alt="Trening majica - plava"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Trening majica - plava</h4>
            <p>Dodatna oprema</p>
          </div>
          {/* POLO MAJICA */}
          <div
            className="kit-box"
            onClick={() =>
              handleOpenModal(
                [PoloPrednja, PoloIza],
                ["Polo majica - plava", "Polo majica - plava"]
              )
            }
          >
            <img
              src={PoloPrednja}
              alt="POLO MAJICA"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Polo majica</h4>
            <p>Dodatna oprema</p>
          </div>

          {/* DUKSA */}
          <div
            className="kit-box"
            onClick={() =>
              handleOpenModal([DuksaPrednja, DuksaIza], ["Duksa", "Duksa"])
            }
          >
            <img
              src={DuksaPrednja}
              alt="Duksa"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Duksa</h4>
            <p>Dodatna oprema</p>
          </div>

          {/* JAKNA */}
          <div
            className="kit-box"
            onClick={() =>
              handleOpenModal([JaknaPrednja, JaknaIza], ["Jakna", "Jakna"])
            }
          >
            <img
              src={JaknaPrednja}
              alt="Jakna"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Jakna</h4>
            <p>Dodatna oprema</p>
          </div>
          {/* SVEČANA */}
          <div
            className="kit-box"
            onClick={() =>
              handleOpenModal(
                [SvečanaPrednja, SvečanaIza],
                ["Svečana trenirka", "Svečana trenirka"]
              )
            }
          >
            <img
              src={SvečanaPrednja}
              alt="Svečana trenirka"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Svečana trenirka</h4>
            <p>Dodatna oprema</p>
          </div>
          {/* ŠUŠKAVAC */}
          <div
            className="kit-box"
            onClick={() =>
              handleOpenModal(
                [ŠuškavacPrednji, ŠuškavacIza],
                ["Šuškavac", "Šuškavac"]
              )
            }
          >
            <img
              src={ŠuškavacPrednji}
              alt="Šuškavac"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Šuškavac</h4>
            <p>Dodatna oprema</p>
          </div>
          {/* RADNA TRENIRKA */}
          <div
            className="kit-box"
            onClick={() =>
              handleOpenModal(
                [RadnaTrenirkaPrednja, RadnaTrenirkaIza],
                ["Radna trenirka", "Radna trenirka"]
              )
            }
          >
            <img
              src={RadnaTrenirkaPrednja}
              alt="Radna trenirka"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Radna trenirka</h4>
            <p>Dodatna oprema</p>
          </div>
          {/* TRENIRKA */}
          <div
            className="kit-box"
            onClick={() =>
              handleOpenModal(
                [TrenirkaPrednja, TrenirkaBočno],
                ["Trenirka", "Trenirka"]
              )
            }
          >
            <img
              src={TrenirkaPrednja}
              alt="Trenirka"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Trenirka</h4>
            <p>Dodatna oprema</p>
          </div>

          {/* TRENING HLAČICE */}
          <div
            className="kit-box"
            onClick={() =>
              handleOpenModal(
                [KratkeHlačePrednja, KratkeHlačeBočna],
                ["Trening hlačice", "Trening hlačice"]
              )
            }
          >
            <img
              src={KratkeHlačePrednja}
              alt="Hlačice za trening"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Trening hlačice</h4>
            <p>Dodatna oprema</p>
          </div>
          {/* *** */}
        </div>
        {/* PAKETI */}
        <h3>PAKETI</h3>
        {/* OSNOVNI PAKET */}
        <div className="oprema-box">
          <div
            className="kit-box"
            onClick={() =>
              handleOpenModal(
                [
                  OsnovniPaket,
                  DomaćiDresPrednji,
                  GostujućiDresPrednji,
                  MajicaPrednja,
                ],
                [
                  "Osnovni paket",
                  "1x Domaći dres",
                  "1x Gostujući dres",
                  "1x Trening majica",
                ]
              )
            }
          >
            <img
              src={OsnovniPaket}
              alt="Osnovni paket"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Osnovni paket</h4>
            <p>Paket oprema</p>
          </div>
          {/* PODSUSED PAKET */}
          <div
            className="kit-box"
            onClick={() =>
              handleOpenModal(
                [
                  PodsusedPaket,
                  DomaćiDresPrednji,
                  GostujućiDresPrednji,
                  SvečanaPrednja,
                  SvečanaIza,
                  TrenirkaPrednja,
                  TrenirkaBočno,
                  ŠuškavacPrednji,
                  ŠuškavacIza,
                  Ruksak,
                ],
                [
                  "Podsused paket",
                  "1x Domaći dres",
                  "1x Gostujući dres",
                  "1x Svećana trenirka - prednja",
                  "Svećana trenirka - zadnja",
                  "1x Trenirka - prednja",
                  "Trenirka - bočno",
                  "1x Šuškavac - prednja",
                  "Šuškavac - zadnja",
                  "1x Ruksak",
                ]
              )
            }
          >
            <img
              src={PodsusedPaket}
              alt="Podsused paket"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Podsused paket</h4>
            <p>Paket oprema</p>
          </div>
          {/* TRENING PAKET */}
          <div
            className="kit-box"
            onClick={() =>
              handleOpenModal(
                [
                  TreningPaket,
                  MajicaBijela,
                  MajicaPrednja,
                  MajicaIza,
                  KratkeHlačePrednja,
                  KratkeHlačeBočna,
                ],
                [
                  "Trening paket",
                  "2x Trening majica - bijela",
                  "2x Trening majica - plava",
                  "Trening majica - plava",
                  "2x Trening hlačice",
                  "Trening hlačice",
                ]
              )
            }
          >
            <img
              src={TreningPaket}
              alt="Trening paket"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Trening paket</h4>
            <p>Paket oprema</p>
          </div>
          {/* PODSUSED TRENING PAKET */}
          <div
            className="kit-box"
            onClick={() =>
              handleOpenModal(
                [
                  PodsusedTreningPaket,
                  MajicaBijela,
                  MajicaPrednja,
                  MajicaIza,
                  KratkeHlačePrednja,
                  KratkeHlačeBočna,
                  RadnaTrenirkaPrednja,
                  RadnaTrenirkaIza,
                  TrenirkaPrednja,
                  TrenirkaBočno,
                  Kapa,
                ],
                [
                  "Podsused trening paket",
                  "2x Trening majica - bijela",
                  "2x Trening majica - plava",
                  "Trening majica - plava",
                  "2x Trening hlačice",
                  "Trening hlačice",
                  "1x Radna trenirka - prednja",
                  "Radna trenirka - zadnja",
                  "1x Trenirka - prednja",
                  "Trenirka - bočno",
                  "1x Kapa",
                ]
              )
            }
          >
            <img
              src={PodsusedTreningPaket}
              alt="Podsused trening paket"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Podsused trening paket</h4>
            <p>Paket oprema</p>
          </div>
        </div>
        {/* OSTALO */}
        <h3>OSTALO</h3>
        <div className="oprema-box">
          {/* KAPA */}
          <div
            className="kit-box"
            onClick={() => handleOpenModal([Kapa], ["Kapa"])}
          >
            <img
              src={Kapa}
              alt="Kapa"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Kapa</h4>
            <p>Ostalo</p>
          </div>
          {/* ŠAL */}
          <div
            className="kit-box"
            onClick={() => handleOpenModal([Šal], ["Šal"])}
          >
            <img
              src={Šal}
              alt="Šal"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Šal</h4>
            <p>Ostalo</p>
          </div>
          {/* ZASTAVICA VELIKA */}
          <div
            className="kit-box"
            onClick={() =>
              handleOpenModal([ZastavicaVelika], ["Velika zastavica"])
            }
          >
            <img
              src={ZastavicaVelika}
              alt="Zastavica velika"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Velika zastavica</h4>
            <p>Ostalo</p>
          </div>
          {/* ZASTAVICA MALA */}
          <div
            className="kit-box"
            onClick={() => handleOpenModal([ZastavicaMala], ["Mala zastavica"])}
          >
            <img
              src={ZastavicaMala}
              alt="Zastavica mala"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Mala zastavica</h4>
            <p>Ostalo</p>
          </div>
          {/* RUKSAK */}
          <div
            className="kit-box"
            onClick={() => handleOpenModal([Ruksak], ["Ruksak"])}
          >
            <img
              src={Ruksak}
              alt="Ruksak"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Ruksak</h4>
            <p>Ostalo</p>
          </div>
          {/* VREČICA ZA */}
          <div
            className="kit-box"
            onClick={() => handleOpenModal([Vrečica], ["Vrečica za kopačke"])}
          >
            <img
              src={Vrečica}
              alt="Vrečica za kopačke"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
            <h4>Vrečica za kopačke</h4>
            <p>Ostalo</p>
          </div>
        </div>
        <h5>
          Kao član NK Podsused dobivate popust od <span> 10%</span>!!!
        </h5>
        <Link
          onClick={() => handleClick("/")}
          to="/postani-član"
          className="btn postani-clan"
        >
          Postani član
        </Link>
        <div className="light-border"></div>
        <h3>Naručite opremu!</h3>
        <h4>Predsjednik</h4>
        <p>Nikola Lipovac</p>
        <a href="tel:099 410 1487" className="links">
          +385 099 410 1487
        </a>
        <h4>Tajnik</h4>
        <p>Mario Berend</p>
        <a href="tel:098 738 506" className="links">
          +385 098 738 506
        </a>

        <ImageModal
          isOpen={isModalOpen}
          images={modalImages}
          titles={modalTitles}
          onClose={handleCloseModal}
        />
      </div>
    </>
  );
}
/* ************* */

/* ************* */
const ImageModal = ({ isOpen, images = [], titles = [], onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Ako modal nije otvoren ili nema slika, ne prikazuj modal
  if (!isOpen || images.length === 0) return null;

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Zatvori modal kad se klikne izvan sadržaja
  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const preventContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <div className="modal" onClick={handleBackgroundClick}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <img
          src={images[currentImageIndex]}
          alt="Oprema"
          onContextMenu={preventContextMenu}
          style={{ userSelect: "none" }}
          loading="lazy"
        />

        {/* Prikaz naslova ispod slike */}
        <p>{titles[currentImageIndex]}</p>

        {/* Prikaži navigacijske gumbe samo ako ima više slika */}
        {images.length > 1 && (
          <>
            <button className="oprema-btn prev-btn" onClick={handlePrevious}>
              &#8249;
            </button>
            <button className="oprema-btn next-btn" onClick={handleNext}>
              &#8250;
            </button>
          </>
        )}
      </div>
    </div>
  );
};
