// Slike
import NKSloboda1977 from "../../../Assets/Images/Klub/NK-Sloboda-1977.jpg";
import SretanUskrs from "../../../Assets/Images/Novosti/Sretan-Uskrs.jpg";
import WebStranica from "../../../Assets/Images/Novosti/WebStranica.jpg";
import WebStranica1 from "../../../Assets/Images/Novosti/WebStranica1.jpg";
import WebStranica2 from "../../../Assets/Images/Novosti/WebStranica2.jpg";
import WebStranica3 from "../../../Assets/Images/Novosti/WebStranica3.jpg";
import WebStranica4 from "../../../Assets/Images/Novosti/WebStranica4.jpg";
import NovaSezona from "../../../Assets/Images/Novosti/NovaSezona.jpg";
import Veterani2425 from "../../../Assets/Images/Ostalo/Veterani-sezona24-25.jpg";

// Slug function

function createSlug(text) {
  return text
    .toLowerCase()
    .trim()
    .replace(/[ćčšžđ]/g, (c) => {
      return { ć: "c", č: "c", š: "s", ž: "z", đ: "d" }[c];
    })
    .replace(/[^a-z0-9\s-]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");
}

const newsData = [
  {
    slug: createSlug(
      "Predstavljamo Vam novu Web stranicu Nogometnog kluba Podsused!"
    ),
    image: WebStranica,
    imageAlt: "NK Podsused Web stranica",
    author: "NK Podsused",
    subtitle: "Novosti",
    title: "Predstavljamo Vam novu Web stranicu Nogometnog kluba Podsused!",
    mainTitle: "Predstavljamo Vam novu Web stranicu Nogometnog kluba Podsused!",
    bio1: "S ponosom Vam predstavljamo potpuno novu Web stranicu našeg nogometnog kluba Podsused!",
    bio2: " Naša nova stranica dizajnirana je s fokusom na modernost i jednostavnost korištenja, kako bismo Vam omogućili najlakši i najbrži pristup svim informacijama o klubu.",
    title1: "ŠTO JE NOVO?",
    title2: "Moderan izgled:",
    text2:
      "Nova stranica dolazi u bojama našeg kluba i nudi opcije tamnog (🌙) i svijetlog (🔅) načina rada, koje možete lako prilagoditi klikom na gumb mjeseca 🌙 i sunca 🔅.",
    image2: WebStranica1,
    author2: "NK Podsused",
    title3: "Poboljšana navigacija",
    text3:
      "Naša navigacija na vrhu stranice (3 crtice u gornjem desnom kutu) omogućuje brzi pristup svim ključnim informacijama, uključujući povijest kluba, najnovije novosti, nadolazeće događaje, raspored utakmica, detalje o našim momčadima i ostalo.",
    image3: WebStranica2,
    author3: "NK Podsused",
    title4: "Oprema i članstvo: ",
    text4:
      "Isto tako možete pregledati i naručiti najnoviju klupsku opremu i dobiti 10% popusta našeg sponzora i proizvođača opreme TOTAL SPORT j.d.o.o. prilikom kupnje bilo kojeg asortimana ako postanete član kluba.",
    image4: WebStranica3,
    author4: "NK Podsused",
    title5: "Galerija i ostale informacije:",
    text5:
      " Na dnu stranice možete pronaći informacije kao raspored treninga, galerija, oprema i još mnogo toga.",
    image5: WebStranica4,
    author5: "NK Podsused",
    text6:
      "Naša nova stranica tu je da poboljša Vaše iskustvo i omogući Vam da uvijek budete u korak s najnovijim informacijama i događanjima u klubu. Posjetite nas i istražite sve što smo pripremili za Vas!",
    endText: "Vaš NK Podsused!",
    date: "",
  },
  /* ***********************NOVOSTI*********************** */
  {
    slug: createSlug("Nova sezona počela za sve selekcije NK Podsuseda"),
    image: Veterani2425,
    imageAlt: "Početak sezone",
    author: "NK Podsused",
    subtitle: "Novosti sa utakmica",
    title: "Nova sezona počela za sve selekcije NK Podsuseda",
    mainTitle: "Nova sezona počela za sve selekcije NK Podsuseda",
    bio1: "Prošli ponedjeljak su prvi s natjecanjem startali naši veterani. U prvoj utakmici nove sezone na domaćem terenu predstavili smo novu garnituru veteranskih dresova i nikad većem broju prijavljenih igrača.",
    bio2: " Zahvaljujemo sponzorima na novoj garnituri.U korektnoj i fer utakmici od nas su bili bolji VNK Kvart. Čestitke svima i nadamo se boljem nastavku sezone. Treće poluvrijeme ipak je popravilo dojam 😁😁 ",

    bio3: "Isto tako ovaj vikend su krenule sve lige za ostale selekcije. Osim Seniora, Mlađih pionira i  limača sve ekipe su upisale pobjede.  Čestitamo svima i neka nastave i dalje marljivo trenirati.  ",
    title2: "Ostale utakmice:",
    title3: "2.ZNL Seniori",
    text3: "NK Dragonožec 2 - 1 NK Podsused",
    title4: "1.ZNL Kadeti ",
    text4: "NK Podsused - NK Trešnjevka - 3:2",
    title5: "2.ZNL Kadeti",
    text5: "NK Šparta - NK Podsused - 2:4",
    title6: "1.ZNL Pioniri",
    text6: "NK Ponikve - NK Podsused - 2:3",
    title7: "1.ZNL Mlađi pioniri",
    text7: "NK Ponikve - NK Podsused - 2:0",
    title8: "3.ZNL Pioniri",
    text8: "NK Podsused - NK Most - 3:1",
    title9: "3.D ZNL Limači",
    text9: "NK Podsused - NK Mladost Buzin - 0:2",
    title10: "3.D ZNL Zagići",
    text10: "NK Podsused - NK Mladost Buzin - 18:0",
    endText: "Vaš NK Podsused.",
    date: "10.09.2024.",
  },
  {
    image: NovaSezona,
    slug: createSlug(
      "Uskoro kreće nova sezona NK Podsused nakon intenzivnih priprema"
    ),
    imageAlt: "Nova sezona",
    author: "NK Podsused",
    subtitle: "Novosti",
    title: "Počinje nova sezona 2024/2025!",
    mainTitle: "NK Podsused Započinje Novu Sezonu u 2. Zagrebačkoj Ligi",
    text1: "Dragi navijači NK Podsused! ",
    text2:
      "S velikim uzbuđenjem najavljujemo početak nove sezone u 2. zagrebačkoj ligi! Nakon intenzivnih priprema i napornog rada tijekom ljetne pauze, naši igrači spremni su za nove izazove i borbu za što bolji plasman u ovoj ligi.",
    text3:
      "Prvu utakmicu ove sezone odigrat ćemo sljedeći vikend, 08.09.2024., u gostima protiv NK Dragonožec. Ovaj susret bit će pravi test za našu momčad, a vjerujemo da ćemo uz podršku naših vjernih navijača uspjeti ostvariti pozitivan rezultat i tako otvoriti sezonu na najbolji mogući način.",
    text4:
      "Klubovi sa kojima će NK Podsused igrati ove sezone u 2. zagrebačkoj ligi su: NK Croatia 98, NK Čehi, NK Čulinec, NK Ćiro Academy, NK Dragonožec, NK Hrašće, NK Ivanja Reka, NK Sloga Zagreb, NK Strmec Zagreb i NK Zagreb 041.",
    text5:
      "Pozivamo sve naše navijače da nas podrže u ovoj sezoni, bilo na domaćim utakmicama ili gostovanjima. Vaša podrška nam je uvijek bila vjetar u leđa i sigurni smo da će tako biti i ove godine. Zajedno možemo postići velike stvari!",
    endText: "Vaš NK Podsused.",
    date: "28.08.2024.",
  },
  /*   {
    slug: createSlug(
      "Ovaj vikend završila je sezona 23-24 za Seniore NK Podsused"
    ),
    image: Galerija1,
    imageAlt: "NK Podsused Web stranica",
    subtitle: "Novosti",
    title: "Završila sezona 2023/2024 za Seniore NK Podsuseda...",
    mainTitle: "NK Podsused ispao iz 1. Zagrebačke lige",
    text1:
      "Nakon zadnje utakmice Podsuseda koja je bila bitna za opstanak u 1. Zagrebačkoj ligi Podsused je izgubio rezultatom 1 - 3 protiv Šparte Zagreb te ostao sa 2 boda razlike i boljom gol razlikom ispod Botinca  koji je osvojio 3 boda protiv Prigorja (M) i tako zadržao 15. mjesto sa 22 boda.",
    image2: Galerija2,
    text2:
      "Na kraju ove sezone 23/24 NK Podsused je imao 5 pobjeda, 5 neriješenih i 20 izgubljenih utakmica. Podsused je postigao sveukupno 39 golova i primio 88, a najbolji strijelac Podsuseda je bio Stipe Miloš koji je postigao 9 golova.",
    image3: Galerija8,
    endText: "NK Podsused",
    date: "02.06.2024.",
  }, */

  {
    slug: createSlug(
      "Ovu nedjelju slavimo Uskrs, jedan od najvažnijih kršćanskih blagdana..."
    ),
    image: SretanUskrs,
    imageAlt: "Sretan Uskrs",
    author: "NK Podsused",
    subtitle: "Uskrs",
    title:
      "Ovu nedjelju slavimo Uskrs, jedan od najvažnijih kršćanskih blagdana...",
    mainTitle: "Sretan i blagoslovljen USKRS!",
    text1:
      "Ovu nedjelju slavimo Uskrs, jedan od najvažnijih kršćanskih blagdana diljem svijeta. Uskrs je dan koji obilježava uskrsnuće Isusa Krista iz mrtvih prema kršćanskom vjerovanju. Ova proslava donosi nadu, radost i obnovu vjere.",
    text2:
      "Uskrs je vrijeme kada se mnogi vjernici okupljaju s obitelji i prijateljima, dijeleći radost i blagoslove ovog svetog dana. To je vrijeme kada se istinski osjeća zajedništvo i ljubav među ljudima.",
    text3:
      "U ime NK Podsused uprave, želimo Vam sretan i blagoslovljen Uskrs. Neka ovaj dan bude ispunjen mirom, ljubavlju i radošću za Vas i vaše najmilije.",
    endText: "NK Podsused",
    date: "27.03.2024.",
  },

  {
    slug: createSlug("Jeste li znali da je NK Podsused u svojoj dugoj..."),
    image: NKSloboda1977,
    imageAlt: "NK Sloboda 1977",
    author: "NK Sloboda 1977",
    subtitle: "Zanimljivosti",
    title: "Jeste li znali da je NK Podsused u svojoj dugoj...",
    mainTitle: "Jeste li znali?",
    text1:
      "Jeste li znali da je NK Podsused u svojoj dugoj povijesti promijenio ime kluba čak 6 PUTA?",
    text2:
      "NK Croatia ,NK Dolomit, NK Sloboda, NK Hidroelektra, ponovo NK Sloboda i na kraju NK Podsused.",
    endText: "NK Podsused",
    date: "10.03.2024.",
  },
];

export default newsData;
