import React, { useState, useEffect } from "react";
import Main from "../Home/Main";
import { Link } from "react-router-dom";
// Images
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

export default function Quiz() {
  const handleClick = (path) => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    document.title = "NK Podsused | Kviz";
  }, []);
  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="NK PODSUSED KVIZ"
      />
      <div id="quiz-section" className="section">
        <Link to="/" className="go-back-btn" onClick={() => handleClick("/")}>
          Vrati se natrag
        </Link>
        <div>
          <h2>KOLIKO ZNATE O NK PODSUSEDU?</h2>
          <p>
            U ovom kvizu provjerite svoje znanje koliko dobro znate o Nogometnom
            Klubu Podsused.
          </p>
          <p style={{ marginBottom: "20px" }}>
            Svoje rezultate možete poslati na našu Instagram stranicu!
          </p>
          <Link
            className="links"
            to="https://www.instagram.com/nkpodsused/"
            target="_blank"
          >
            <i className="fi fi-brands-instagram"></i>
          </Link>
        </div>
        <QuizQuestions />
      </div>
    </>
  );
}
const initialQuestions = [
  {
    question: "Koje godine je osnovan NK Podsused?",
    correctAnswer: "1921",
    options: ["1921", "1930", "1945", "1955"],
  },
  {
    question: "Pod kojim imenom je Podsused prvo zaigrao?",
    correctAnswer: "NK Croatia",
    options: ["NK Croatia", "NK Dolomit", "NK Susedgrad", "NK Sloboda"],
  },
  {
    question: "Koje godine je Podsused igrao protiv prve momčadi Hajduka?",
    correctAnswer: "1978",
    options: ["1928", "1980", "1995", "1978"],
  },
  {
    question: "Tko je bio prvi predsjednik Podsuseda?",
    correctAnswer: "Fridrih Fišer",
    options: [
      "Tornada Ivan",
      "Filip Klešić",
      "Fridrih Fišer",
      "Dragutin Karlović",
    ],
  },
  {
    question: "Koliko NK Podsused trenutno ima registriranih igrača?",
    correctAnswer: "186",
    options: ["142", "186", "84", "126"],
  },
  /* {
    question: "Koja 3 sponzora se nalaze na dresu NK Podsuseda?",
    correctAnswer: "Dojkić, Duplico, Maricus",
    options: [
      "Kod Bake, Dojkić, Marcius",
      "Dojkić, Davos, Marcius",
      "Dojkić, Duplico, Maricus",
      "Duplico, Dojkić, Petar Rauch",
    ],
  }, */
  {
    question: "Koja je prava adresa NK Podsuseda?",
    correctAnswer: "Kupališni put 1",
    options: [
      "Kitov prolaz 1",
      "Kupališna ulica 1",
      "Samoborska cesta 1a",
      "Kupališni put 1",
    ],
  },
  {
    question: "Gdje se nalazilo prvo igralište NK Podsuseda?",
    correctAnswer: "U krugu Tvornice cementa",
    options: [
      "Imanje Gregurić",
      "Kupališni put 1",
      "U krugu Tvornice cementa",
      "Susedsko polje",
    ],
  },
  {
    question: "Kada je NK Podsused promijenio ime u NK Hidroelektra?",
    correctAnswer: "1978",
    options: ["1978", "1965", "1945", "1992"],
  },
  /*   {
    question: "Koja se sportska marka nalazi na dresu NK Podsuseda?",
    correctAnswer: "Fotex",
    options: ["Alpas", "Reusch", "Fotex", "Kipsta"],
  }, */
  {
    question: "Kolika je dužina terena NK Podsused?",
    correctAnswer: "97m",
    options: ["105m", "102m", "94m", "97m"],
  },
  /* {
    question: "Trenutni predsjednik NK Podsuseda je?",
    correctAnswer: "Nikola Lipovac",
    options: [
      "Nikola Lipovac",
      "Mario Berend",
      "Mario Lipovac",
      "Nikola Berend",
    ],
  }, */
  {
    question: "Koliko puta je Podsused promijenio naziv kluba?",
    correctAnswer: "6",
    options: ["3", "6", "4", "5"],
  },
  /*   {
    question: "Navijači NK Podsuseda zovu se:",
    correctAnswer: "",
    options: ["", "", "", ""],
  }, */
];

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

function QuizQuestions() {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [round, setRound] = useState(1);
  const [answerClass, setAnswerClass] = useState("");
  const [clickedAnswer, setClickedAnswer] = useState(null);

  useEffect(() => {
    const shuffledQuestions = shuffleArray(initialQuestions)
      .slice(0, 5)
      .map((question) => ({
        ...question,
        options: shuffleArray(question.options),
      }));
    setQuestions(shuffledQuestions);
  }, [setQuestions]);

  let message = "";
  let emoji = "";

  switch (score) {
    case 0:
      message = "Nažalost niste pogodili niti jedno pitanje.";
      emoji = <i class="fi fi-rs-sad"></i>;
      break;
    case 1:
      message = "Možeš ti to bolje!";
      emoji = <i class="fi fi-rs-meh"></i>;
      break;
    case 2:
      message = "Dobro ste krenuli, ali imaš još prostora za poboljšanje!";
      emoji = <i class="fi fi-rs-smile-wink"></i>;
      break;
    case 3:
      message = "Solidno! Možeš ti to bolje?";
      emoji = <i class="fi fi-rs-smile-beam"></i>;
      break;
    case 4:
      message = "Vrlo dobro! Skoro sve točno.";
      emoji = <i class="fi fi-rs-laugh-squint"></i>;
      break;
    case 5:
      message = "Savršeno! Sve ste pogodili!";
      emoji = <i class="fi fi-rs-grin-stars"></i>;
      break;
    default:
      message = "Nastavi s trudom!";
      break;
  }

  const currentQuestion = questions[currentQuestionIndex];

  const handleAnswer = (selectedAnswer) => {
    const isCorrect = selectedAnswer === currentQuestion.correctAnswer;
    setAnswerClass(isCorrect ? "correct" : "wrong");
    setClickedAnswer(selectedAnswer); //
    setTimeout(() => {
      if (isCorrect) {
        setScore((prevScore) => prevScore + 1);
      }

      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setAnswerClass("");
        setClickedAnswer(null);
        setRound((prevRound) => prevRound + 1);
      } else {
        setCurrentQuestionIndex(-1);
      }
    }, 2000);
  };

  const resetQuiz = () => {
    setCurrentQuestionIndex(0);
    setScore(0);
    setRound(1);
    setAnswerClass("");
    setClickedAnswer(null);
    setQuestions(
      shuffleArray(initialQuestions)
        .slice(0, 5)
        .map((question) => ({
          ...question,
          options: shuffleArray(question.options),
        }))
    );
  };

  useEffect(() => {}, [score]);

  if (currentQuestionIndex === -1) {
    return (
      <div className="quiz-finish">
        <h2>GOTOV KVIZ!</h2>
        <p>Broj bodova: {score}/5</p>

        <p className="emoji">
          <span>{emoji}</span> {message}
        </p>
        <button onClick={resetQuiz}>IGRAJ PONOVO</button>
      </div>
    );
  }

  return (
    <div className="quiz">
      <h2>{round}. Pitanje</h2>
      <p>{currentQuestion && currentQuestion.question}</p>
      <ul>
        {currentQuestion &&
          currentQuestion.options.map((option, index) => (
            <li key={index}>
              <button
                onClick={() => handleAnswer(option)}
                className={
                  answerClass === ""
                    ? ""
                    : option === currentQuestion.correctAnswer
                    ? "correct" +
                      (option === clickedAnswer ? " clicked-correct" : "")
                    : option === clickedAnswer
                    ? "wrong clicked-wrong"
                    : "wrong"
                }
                disabled={answerClass !== ""}
              >
                {option}
              </button>
            </li>
          ))}
      </ul>
    </div>
  );
}
