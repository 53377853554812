import React from "react";
import { Link } from "react-router-dom";
import newsData from "./NewsData";

export default function News({ slug }) {
  const news = newsData.find((item) => item.slug === slug);

  if (!news) {
    return <div>Novost nije pronađena!</div>;
  }

  const preventContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <li className="novosti-box">
      <Link to={`/novosti/${news.slug}`}>
        {" "}
        {/* Koristi slug */}
        <div className="novosti-img">
          {news.image ? (
            <img
              src={news.image}
              alt={news.title}
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
          ) : (
            <div>Slika nije dostupna.</div>
          )}
          <div className="novosti-btn">Više...</div>
        </div>
        <span>{news.subtitle}</span>
        <h4>{news.title}</h4>
        <span>{news.date}</span>
      </Link>
    </li>
  );
}
