import React, { useState } from "react";
import NKPodsused from "../../../Assets/Images/Klubovi/NK-Podsused.png";
import NKCroatia98 from "../../../Assets/Images/Klubovi/NK-Croatia98.png";
import NKCehi from "../../../Assets/Images/Klubovi/NK-Cehi.png";
import NKCiroAcademy from "../../../Assets/Images/Klubovi/NK-Ciro-Academy.png";
import NKCulinec from "../../../Assets/Images/Klubovi/NK-Culinec.png";
import NKDragonozec from "../../../Assets/Images/Klubovi/NK-Dragonozec.png";
import NKGavran from "../../../Assets/Images/Klubovi/NK-Gavran.png";
import NKHrasce from "../../../Assets/Images/Klubovi/NK-Hrasce.png";
import NKIvanjaReka from "../../../Assets/Images/Klubovi/NK-Ivanja-Reka.png";
import NKSloga from "../../../Assets/Images/Klubovi/NK-Sloga.png";
import NKStrmec from "../../../Assets/Images/Klubovi/NK-Strmec.png";
import NKZagreb041 from "../../../Assets/Images/Klubovi/NK-Zagreb041.png";

const match = 2;

const standingsData = [
  { klub: "NK Čehi", utk: match, raz: "+8", bod: "6" },
  { klub: "NK Dragonožec", utk: match, raz: "+7", bod: "6" },
  { klub: "NK Čulinec", utk: match, raz: "+5", bod: "6" },
  { klub: "NK Zagreb 041", utk: match, raz: "+2", bod: "6" },
  { klub: "NK Podsused", utk: match, raz: "+1", bod: "3" },
  { klub: "NK Ćiro Academy", utk: match, raz: "0", bod: "3" },
  { klub: "NK Ivanja Reka", utk: match, raz: "-5", bod: "3" },
  { klub: "NK Hrašće", utk: match, raz: "-2", bod: "1" },
  { klub: "NK Sloga Zagreb", utk: match, raz: "-2", bod: "1" },
  { klub: "NK Strmec Zagreb", utk: match, raz: "-2", bod: "0" },
  { klub: "NK Gavran 2003", utk: match, raz: "-4", bod: "0" },
  { klub: "NK Croatia 98", utk: match, raz: "-8", bod: "0" },
];

const clubImages = {
  "NK Podsused": NKPodsused,
  "NK Croatia 98": NKCroatia98,
  "NK Čehi": NKCehi,
  "NK Čulinec": NKCulinec,
  "NK Ćiro Academy": NKCiroAcademy,
  "NK Dragonožec": NKDragonozec,
  "NK Gavran 2003": NKGavran,
  "NK Hrašće": NKHrasce,
  "NK Ivanja Reka": NKIvanjaReka,
  "NK Sloga Zagreb": NKSloga,
  "NK Strmec Zagreb": NKStrmec,
  "NK Zagreb 041": NKZagreb041,
};

const TeamRow = ({ team, index }) => {
  const rowClass = team.klub === "NK Podsused" ? "row podsused" : "row";
  return (
    <li className={rowClass}>
      <h5>{index}</h5>
      <p>
        <img
          src={clubImages[team.klub]}
          alt={`${team.klub} logo`}
          className="club-logo"
          loading="lazy"
        />
        {team.klub}
      </p>
      <p>{team.utk}</p>
      <p>{team.raz}</p>
      <p>{team.bod}</p>
    </li>
  );
};

const StandingsTable = ({ standingsData, showAll, podsusedIndex }) => {
  let dataToDisplay;

  if (showAll) {
    dataToDisplay = standingsData;
  } else if (podsusedIndex <= 4) {
    dataToDisplay = standingsData.slice(0, 5);
  } else if (podsusedIndex >= standingsData.length - 5) {
    dataToDisplay = standingsData.slice(-5);
  } else {
    const start = Math.max(podsusedIndex - 2, 0);
    dataToDisplay = standingsData.slice(start, start + 5);
  }

  return (
    <ul className="table">
      <li className="row">
        <h5>#</h5>
        <h5>Klub</h5>
        <h5>Ut.</h5>
        <h5>G +/-</h5>
        <h5>Bod</h5>
      </li>
      {dataToDisplay.map((team, index) => (
        <TeamRow
          key={index}
          team={team}
          index={standingsData.indexOf(team) + 1}
        />
      ))}
    </ul>
  );
};

export default function Standings() {
  const [showAll, setShowAll] = useState(false);

  // Pronađi poziciju NK Podsused
  const podsusedIndex = standingsData.findIndex(
    (team) => team.klub === "NK Podsused"
  );

  const toggleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  return (
    <>
      <StandingsTable
        standingsData={standingsData}
        showAll={showAll}
        podsusedIndex={podsusedIndex}
      />
      <button className="standings-btn" onClick={toggleShowAll}>
        {showAll ? "SMANJI LJESTVICU" : "POGLEDAJ CIJELU LJESTVICU"}
      </button>
    </>
  );
}
