import React, { useState } from "react";
import Ball from "../../../Assets/Images/Ostalo/Ball.png";
import RedBall from "../../../Assets/Images/Ostalo/Ball-red.png";
import YellowCard from "../../../Assets/Images/Ostalo/YellowCard.png";
import RedCard from "../../../Assets/Images/Ostalo/RedCard.png";
import SubIn from "../../../Assets/Images/Ostalo/SubIn.png";
import SubOut from "../../../Assets/Images/Ostalo/SubOut.png";
import Postave from "../../../Assets/Images/Ostalo/Postave.png";
import Player from "../../../Assets/Images/Ostalo/Player.png";

export default function Matches(props) {
  const [showDetails, setShowDetails] = useState(false);

  const handleMoreInfoClick = () => {
    setShowDetails(!showDetails);
  };

  return (
    <>
      <div id={props.id} className="match-info">
        <h2>{props.match}</h2>
        <div className="match-result">
          <div className="team-info slide-right">
            <h3 className="team-name">{props.homeTeam}</h3>
            {props.homeTeamLogo && (
              <img
                className="match-team-logo"
                src={props.homeTeamLogo}
                alt={props.homeTeam}
                loading="lazy"
              />
            )}
          </div>
          <div className="final-result">
            <h2>{props.result}</h2>
          </div>
          <div className="slide-left team-info">
            <h3 className="team-name">{props.awayTeam}</h3>
            {props.awayTeamLogo && (
              <img
                className="match-team-logo"
                src={props.awayTeamLogo}
                alt={props.awayTeam}
                loading="lazy"
              />
            )}
          </div>
        </div>
        <h5>
          <i className="fi fi-rs-calendar-days"></i>
          {props.date} - {props.time}
        </h5>
        <h5>
          <i className="fi fi-rs-marker"></i>
          {props.location}
        </h5>
        {/* *****MATCH DETAILS***** */}

        <div className="match-details">
          {showDetails && (
            <>
              <h4>{props.noInfo}</h4>
              {props.details && props.details.length > 0 && (
                <Details details={props.details} />
              )}
              {props.players && props.players.length > 0 && (
                <Lineups
                  players={props.players}
                  benchPlayers={props.benchPlayers}
                />
              )}
            </>
          )}
        </div>

        {(props.details?.length > 0 || props.players?.length > 0) && (
          <button className="details-btn" onClick={handleMoreInfoClick}>
            {showDetails ? "Prikaži manje" : "Detalji sa utakmice"}
          </button>
        )}
      </div>
    </>
  );
}

function Details(props) {
  const renderEventImage = (event) => {
    switch (event) {
      case "goal":
        return <img src={Ball} alt="Goal" loading="lazy" />;
      case "autogoal":
        return <img src={RedBall} alt="Autogoal" loading="lazy" />;
      case "red":
        return <img src={RedCard} alt="Red Card" loading="lazy" />;
      case "yellow":
        return <img src={YellowCard} alt="Yellow Card" loading="lazy" />;
      default:
        return null;
    }
  };

  return (
    <>
      <ul>
        {props.details.map((detail, index) => (
          <li className="details" key={index}>
            {detail.home !== undefined && ( // Provjera za prikaz detail.home
              <span className="right">
                {detail.home} {renderEventImage(detail.HE)}
              </span>
            )}
            {detail.Hout &&
              detail.Hin && ( // Provjera za prikaz zamjene ako postoji
                <div className="substitution right">
                  <span className="sub-in">
                    {detail.Hin} <img src={SubIn} alt="Sub in" />
                  </span>
                  <span className="sub-out">
                    {detail.Hout} <img src={SubOut} alt="Sub out" />
                  </span>
                </div>
              )}
            {detail.Aout &&
              detail.Ain && ( // Provjera za prikaz zamjene ako postoji
                <div className="substitution left">
                  <span className="sub-in">
                    <img
                      className="rotate"
                      src={SubIn}
                      alt="Sub in"
                      loading="lazy"
                    />{" "}
                    {detail.Ain}
                  </span>
                  <span className="sub-out">
                    <img
                      className="rotate"
                      src={SubOut}
                      alt="Sub out"
                      loading="lazy"
                    />{" "}
                    {detail.Aout}
                  </span>
                </div>
              )}
            {detail.away !== undefined && ( // Provjera za prikaz detail.away
              <span className="left">
                {renderEventImage(detail.AE)} {detail.away}
              </span>
            )}
          </li>
        ))}
      </ul>
    </>
  );
}

function Lineups(props) {
  return (
    <>
      <div className="lineups">
        <div className="lineups-stadium">
          <img src={Postave} alt="Teren" loading="lazy" />
          <div className="players">
            {props.players &&
              props.players.length > 0 &&
              props.players.map((player, index) => (
                <div key={index} className={`player ${player.pos}`}>
                  <img
                    src={Player}
                    alt={player.name}
                    className="player-img"
                    loading="lazy"
                  />
                  <span>{player.name}</span>
                </div>
              ))}
          </div>
        </div>
        <div className="bench-players">
          {props.benchPlayers &&
            props.benchPlayers.length > 0 &&
            props.benchPlayers.map((player, index) => (
              <div key={index} className={`player ${player.pos}`}>
                <img
                  src={Player}
                  alt={player.name}
                  className="player-img"
                  loading="lazy"
                />
                <h6>{player.name}</h6>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
