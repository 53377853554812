import Main from "../Home/Main";
import { Link } from "react-router-dom";
import { useEffect } from "react";

// Images
import NoPlayer from "../../../Assets/Images/Selekcije/Seniori/No-Player.jpg";
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

export default function Seniori() {
  useEffect(() => {
    document.title = "NK Podsused | Mlađi pioniri 1";
  }, []);
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="MLAĐI PIONIRI"
      />
      <div className="seniori section">
        <div className="podsused-team">
          {/* TRENER */}
          <h3>TRENER</h3>
          <div className="team-grid">
            <Coach image={NoPlayer} name="Vedran Polančec" />
          </div>
        </div>
        <Link
          className="btn"
          to="/ostale-utakmice"
          onClick={() => handleClick("/")}
        >
          Utakmice &rarr;
        </Link>
      </div>
    </>
  );
}

function Coach(props) {
  return (
    <div className="player-box">
      <img src={props.image} alt={props.name} />
      <h3>{props.name}</h3>
    </div>
  );
}
