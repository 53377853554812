import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./Styles/App.scss";
import {
  Navigation,
  Home,
  Footer,
  Seniori,
  About,
  Season,
  Kadeti,
  MladiPioniri,
  Limaci,
  Zagici,
  Pioniri,
  Contact,
  PrivacyPolicy,
  TermsOfUse,
  Gallery,
  BackToTopButton,
  Quiz,
  RasporedTreninga,
  Veterani,
  OstaloSeason,
  ErrorPage,
  LoadingScreen,
  NewsDetails,
  NewsPages,
  Oprema,
  Member,
  Donations,
} from "./Components";
import Sponsors from "./Components/Pages/Home/Sponsors";
import NotFoundPage from "./Components/Partials/Errors/NotFoundPage";

const App = () => {
  const [loadingComplete, setLoadingComplete] = useState(false);

  return (
    <BrowserRouter>
      <LoadingScreen setLoadingComplete={() => setLoadingComplete(true)} />
      {loadingComplete && (
        <>
          <Navigation />
          <BackToTopButton />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/raspored-treninga" element={<RasporedTreninga />} />
            {/* SEASON */}
            <Route path="/seniori" element={<Seniori />} />
            <Route path="/ostale-utakmice" element={<OstaloSeason />} />
            {/* SEASON */}
            <Route path="/utakmice" element={<Season />} />
            <Route path="/ostale-utakmice" element={<OstaloSeason />} />
            {/* ABOUT */}
            <Route path="/o-klubu" element={<About />} />
            {/* SELECTIONS */}
            <Route path="/veterani" element={<Veterani />} />
            <Route path="/kadeti" element={<Kadeti />} />
            <Route path="/mlađi-pioniri" element={<MladiPioniri />} />
            <Route path="/pioniri" element={<Pioniri />} />
            <Route path="/limači" element={<Limaci />} />
            <Route path="/zagići" element={<Zagici />} />
            {/* OPREMA */}
            <Route path="/oprema" element={<Oprema />} />
            {/* MEMBER */}
            <Route path="/postani-član" element={<Member />} />
            {/* DONATIONS */}
            <Route path="/donacije" element={<Donations />} />
            {/* NOVOSTI */}
            <Route path="/kviz" element={<Quiz />} />
            {/* NEWS */}
            <Route path="/novosti" element={<NewsPages />} />
            <Route path="/novosti/:slug" element={<NewsDetails />} />
            {/* CONTACT */}
            <Route path="/kontakt" element={<Contact />} />
            {/* GALLERY */}
            <Route path="/galerija" element={<Gallery />} />
            {/* PRIVACY POLICY & TERMS OF USE */}
            <Route path="/politika-privatnosti" element={<PrivacyPolicy />} />
            <Route path="/uvjeti-korištenja" element={<TermsOfUse />} />
            {/* ERRORS */}
            <Route path="*" element={<NotFoundPage />} />
            <Route
              path="error"
              element={<ErrorPage error="Nepoznata greška" />}
            />
          </Routes>
          <Sponsors />
          <Footer />
        </>
      )}
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
