import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../Assets/Images/Nk-Podsused-Logo.png";
import Moon from "../../../Assets/Images/Ostalo/Moon.png";
import Sun from "../../../Assets/Images/Ostalo/Sun.png";

export default function Navigation() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [showSenioriSubmenu, setShowSenioriSubmenu] = useState(false);
  const [navbarStyle, setNavbarStyle] = useState({
    backgroundColor: "transparent",
  });
  const [isNightMode, setIsNightMode] = useState(false);

  useEffect(() => {
    // Close all submenus when the navigation is closed
    if (!show) {
      setShowSubmenu(false);
      setShowSenioriSubmenu(false);
    }
  }, [show]);

  // Close Nav-list when new page is open
  const closeMenu = () => {
    setShow(false);
  };

  // Toggle Momčadi
  const toggleSubmenu = () => {
    setShowSubmenu(!showSubmenu);
    // Close the other submenu when this one is opened
    setShowSenioriSubmenu(false);
  };

  // Toggle Seniori
  const toggleSenioriSubmenu = () => {
    setShowSenioriSubmenu(!showSenioriSubmenu);
    // Close the other submenu when this one is opened
    setShowSubmenu(false);
  };

  // Close submenu
  const closeSubmenu = () => {
    setShowSubmenu(false);
    setShowSenioriSubmenu(false);
  };

  // Scroll to top of the page
  const handleClick = (path) => {
    closeMenu();
    navigate(path);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY / window.innerHeight;
      if (scrolled >= 0.48) {
        if (isNightMode) {
          setNavbarStyle({
            background: "linear-gradient(#182338, #182338f4)",
          });
        } else {
          setNavbarStyle({
            background: "linear-gradient(#154075f4, #204c7deb)",
          });
        }
      } else {
        setNavbarStyle({
          backgroundColor: "transparent",
        });
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isNightMode]);

  // Toggle night mode
  const toggleMode = () => {
    const newMode = !isNightMode;
    setIsNightMode(newMode);
    localStorage.setItem("mode", newMode ? "night" : "light");
  };

  useEffect(() => {
    const savedMode = localStorage.getItem("mode");
    if (savedMode) {
      setIsNightMode(savedMode === "night");
    }
  }, []);

  useEffect(() => {
    // Apply the night mode class to the body element
    if (isNightMode) {
      document.body.classList.add("nightmode");
    } else {
      document.body.classList.remove("nightmode");
    }
  }, [isNightMode]);

  return (
    <nav id="navigation" style={navbarStyle}>
      <Link to="/" onClick={() => handleClick("/")}>
        <img className="navigation-logo" src={Logo} alt="Nk Podsused logo" />
      </Link>
      <div>
        {" "}
        <ul className={show ? "nav-links active" : "nav-links"}>
          <img className="navbar-logo" src={Logo} alt="Nk Podsused logo" />
          <li>
            <Link
              className="navbar-links"
              to="/"
              onClick={() => handleClick("/")}
            >
              NASLOVNA
            </Link>
          </li>
          <li>
            <Link
              className="navbar-links"
              to="/o-klubu"
              onClick={() => handleClick("/")}
            >
              O KLUBU
            </Link>
          </li>
          <li>
            <Link
              className="navbar-links"
              to="/postani-član"
              onClick={() => handleClick("/")}
            >
              POSTANI ČLAN
            </Link>
          </li>
          <li>
            <span className="navbar-links" onClick={toggleSenioriSubmenu}>
              SENIORI <i className="fi fi-rs-caret-down"></i>
              {showSenioriSubmenu && (
                <ul className="submenu">
                  <li>
                    <Link
                      className="submenu-links"
                      to="/seniori"
                      onClick={() => {
                        closeSubmenu();
                        handleClick("/");
                      }}
                    >
                      MOMČAD
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="submenu-links"
                      to="utakmice"
                      onClick={() => {
                        closeSubmenu();
                        handleClick("/");
                      }}
                    >
                      SENIORI UTAKMICE
                    </Link>
                  </li>
                </ul>
              )}
            </span>
          </li>
          <li>
            <Link
              to="ostale-utakmice"
              className="navbar-links"
              onClick={() => {
                closeSubmenu();
                handleClick("/");
              }}
            >
              UTAKMICE
            </Link>
          </li>

          <li>
            <span
              className="navbar-links"
              onClick={() => {
                toggleSubmenu();
              }}
            >
              SELEKCIJE <i className="fi fi-rs-caret-down"></i>
              {showSubmenu && (
                <ul className="submenu">
                  <li>
                    <Link
                      className="submenu-links"
                      to="/veterani"
                      onClick={() => {
                        closeSubmenu();
                        handleClick("/");
                      }}
                    >
                      VETERANI
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="submenu-links"
                      to="/kadeti"
                      onClick={() => {
                        closeSubmenu();
                        handleClick("/");
                      }}
                    >
                      KADETI U16/U17
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="submenu-links"
                      to="/pioniri"
                      onClick={() => {
                        closeSubmenu();
                        handleClick("/");
                      }}
                    >
                      PIONIRI U14/U15
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="submenu-links"
                      to="/mlađi-pioniri"
                      onClick={() => {
                        closeSubmenu();
                        handleClick("/");
                      }}
                    >
                      MLAĐI PIONIRI U12/U13
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="submenu-links"
                      to="/limači"
                      onClick={() => {
                        closeSubmenu();
                        handleClick("/");
                      }}
                    >
                      LIMAČI U10/U11
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="submenu-links"
                      to="/zagići"
                      onClick={() => {
                        closeSubmenu();
                        handleClick("/");
                      }}
                    >
                      ZAGIĆI U08/U09
                    </Link>
                  </li>
                </ul>
              )}
            </span>
          </li>
          <li>
            <Link
              className="navbar-links"
              to="/novosti"
              onClick={() => handleClick("/")}
            >
              NOVOSTI
            </Link>
          </li>
          <li>
            <Link
              className="navbar-links"
              to="/kontakt"
              onClick={() => handleClick("/")}
            >
              KONTAKT
            </Link>
          </li>

          <div className="social-links">
            <Link
              to="https://www.facebook.com/nogometniklubPodsused/?locale=hr_HR"
              target="_blank"
            >
              <i className="fi fi-brands-facebook"></i>
            </Link>
            <Link to="https://www.instagram.com/nkpodsused/" target="_blank">
              <i
                className="fi
         fi fi-brands-instagram"
              ></i>
            </Link>
          </div>
          {/*   <Link className="member-btn" to="/postani-član">
            Postani Član
          </Link> */}
        </ul>
      </div>

      <div
        className={show ? "hamburger active" : "hamburger"}
        onClick={() => setShow(!show)}
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      <button onClick={toggleMode} className="night-mode-btn">
        {isNightMode ? (
          <img src={Sun} alt="Sun" />
        ) : (
          <img src={Moon} alt="Moon" />
        )}
      </button>
    </nav>
  );
}
