import { Link } from "react-router-dom";
import ClanskaIskaznica from "../../../Assets/Images/Ostalo/Članska-iskaznica-prednja.png";

export default function MemberHome() {
  const handleClick = (path) => {
    window.scrollTo(0, 0);
  };

  const preventContextMenu = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <div className="section">
        <h2>POSTANI ČLAN</h2>
        <div className="member-home">
          <h5>Želiš postati član Nogometnog kluba Podsused?</h5>
          <img
            src={ClanskaIskaznica}
            alt="Članska Iskaznica NK Podsused"
            onContextMenu={preventContextMenu}
            style={{ userSelect: "none" }}
            loading="lazy"
          />
          <Link
            onClick={() => handleClick("/")}
            className="btn"
            to="/postani-član"
          >
            POSTANI ČLAN
          </Link>
        </div>
      </div>
    </>
  );
}
