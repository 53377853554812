import React, { useEffect, useState } from "react";
import NoPlayer from "../../../Assets/Images/Selekcije/Seniori/No-Player.jpg";
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import Main from "../Home/Main";
import { Link } from "react-router-dom";

export default function Seniori() {
  const [showImage, setShowImage] = useState("");
  const [playerInfo, setPlayerInfo] = useState(null);

  const openImage = (imageSrc, info) => {
    setShowImage(imageSrc);
    setPlayerInfo(info);
  };

  const closeImage = () => {
    setShowImage("");
    setPlayerInfo(null);
  };

  // Scroll to top of the page
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    document.title = "NK Podsused | Seniori";
  }, []);
  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="VETERANI"
      />
      <div className="section seniori">
        <div className="podsused-team">
          {/* *****NAPADAČI***** */}
          <h3>NAPADAČI</h3>
          <div className="team-grid">
            <Players
              image={NoPlayer}
              name="Veterani"
              number="7"
              info={{
                birth: "",
                position: "Napadač",
                foot: "Lijeva",
              }}
              openImage={openImage}
            />

            <Players
              image={NoPlayer}
              name="Veterani"
              number="11"
              info={{
                birth: "",
                position: "Napadač",
                foot: "Desna",
              }}
              openImage={openImage}
            />
            <Players
              image={NoPlayer}
              name="Veterani"
              number="2"
              info={{
                birth: "",
                position: "Napadač",
                foot: "Desna",
              }}
              openImage={openImage}
            />
          </div>
          {/* *****VEZNI RED***** */}
          <h3>VEZNI IGRAČI</h3>
          <div className="team-grid">
            <Players
              image={NoPlayer}
              name="Veterani"
              number="7"
              info={{
                birth: "",
                position: "Vezni igrač",
                foot: "Lijeva",
              }}
              openImage={openImage}
            />

            <Players
              image={NoPlayer}
              name="Veterani"
              number="11"
              info={{
                birth: "",
                position: "Vezni igrač",
                foot: "Desna",
              }}
              openImage={openImage}
            />
            <Players
              image={NoPlayer}
              name="Veterani"
              number="2"
              info={{
                birth: "",
                position: "Vezni igrač",
                foot: "Desna",
              }}
              openImage={openImage}
            />
          </div>
          {/* *****OBRANA***** */}
          <h3>OBRANA</h3>
          <div className="team-grid">
            <Players
              image={NoPlayer}
              name="Dominik Kušanić"
              number="2"
              info={{
                birth: "15.06.1978.",
                position: "Obrambeni igrač",
              }}
              openImage={openImage}
            />
            <Players
              image={NoPlayer}
              name="Mladen Majaroš"
              number="4"
              info={{
                birth: "14.12.1981.",
                position: "Obrambeni igrač",
              }}
              openImage={openImage}
            />

            <Players
              image={NoPlayer}
              name="Dubravko Jurgan"
              number="5"
              info={{
                birth: "10.08.1974.",
                position: "Obrambeni igrač",
              }}
              openImage={openImage}
            />
            <Players
              image={NoPlayer}
              name="Vedran Lacković"
              number="3"
              info={{
                birth: "04.11.1989.",
                position: "Obrambeni igrač",
              }}
              openImage={openImage}
            />
          </div>
          {/* *****GOLMANI***** */}
          <h3>GOLMANI</h3>
          <div className="team-grid">
            <Players
              image={NoPlayer}
              name="Tomica Herger"
              number="12"
              info={{
                birth: "02.08.1978.",
                position: "Golman",
              }}
              openImage={openImage}
            />
          </div>
          {/* *****TRENER***** */}
          <h3>TRENER</h3>
          <div className="team-grid">
            <Coach image={NoPlayer} name="Veterani" />
          </div>
        </div>
        <Link className="btn" to="/utakmice" onClick={() => handleClick("/")}>
          Utakmice &rarr;
        </Link>
      </div>
      {/* *****SHOW IMAGES ON CLICK***** */}
      {showImage && (
        <div className="overlay overlay-player" onClick={closeImage}>
          <div id="popup">
            <span id="close" onClick={closeImage}>
              &times;
            </span>
            <img
              id="popupImage"
              src={showImage}
              style={{ width: "100%", height: "100%" }}
              alt="Show"
            />
            <div className="player-info">
              <h5>Datum rođenja:</h5>
              <p> {playerInfo.birth}</p>
              <h5>Pozicija:</h5>
              <p>{playerInfo.position}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function Players({ image, name, number, info, openImage }) {
  const handleClick = () => {
    openImage(image, info);
  };
  return (
    <div className="player-box" onClick={handleClick}>
      <img src={image} alt={name} />
      <h3>{name}</h3>
      <p>{number}</p>
    </div>
  );
}

function Coach(props) {
  return (
    <div className="player-box">
      <img src={props.image} alt={props.name} />
      <h3>{props.name}</h3>
    </div>
  );
}
