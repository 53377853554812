import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import ClanskaIskaznica from "../../../Assets/Images/Ostalo/Članska-iskaznica-prednja.png";
import Main from "../Home/Main";
import { useEffect } from "react";

export default function Member() {
  useEffect(() => {
    document.title = "NK Podsused | Postani član";
  }, []);

  const preventContextMenu = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
      />
      <div className="section">
        <h2>POSTANI ČLAN</h2>
        <div className="member">
          <div className="member-box">
            <img
              src={ClanskaIskaznica}
              alt="Članska Iskaznica NK Podsused"
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
          </div>
          <div className="member-box">
            <h5>Želiš postati član Nogometnog kluba Podsused?</h5>
            <h6>Pogodnosti članstva:</h6>
            <ul>
              <li>Članska iskaznica kao suvenir</li>
              <li>
                <span>10%</span> popusta našeg sponzora i proizvođača opreme
                TOTAL SPORT j.d.o.o. prilikom kupnje bilo kojeg asortimana
              </li>

              <li>
                Pristup videozapisa svih domaćih utakmica NK Podsuseda u{" "}
                <span>1080p</span> - USKORO!
              </li>
            </ul>
          </div>
        </div>
        <div className="member-price">
          <p>Cijena:</p>
          <button>40€ / godina</button>
          <div className="light-border"></div>
          <h4>Učlanite se:</h4>
          <p>Email</p>
          <a
            href="mailto:podsused.nk@gmail.com"
            className="links"
            to="podsused.nk@gmail.com"
          >
            <i className="fi fi-rs-at"></i> podsused.nk@gmail.com
          </a>
        </div>
      </div>
    </>
  );
}
