import React, { useState } from "react";
import News from "./News";
import newsData from "./NewsData";
import Main from "../Home/Main";
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";

export default function NewsPages() {
  const [currentPage, setCurrentPage] = useState(1);
  const [filterSubtitle, setFilterSubtitle] = useState("Sve vijesti");
  const newsPerPage = 4;

  // Function to filter news based on subtitle
  const filteredNewsData = newsData.filter((newsItem) => {
    if (filterSubtitle === "Sve vijesti") {
      return true;
    } else if (filterSubtitle === "Ostalo") {
      return (
        newsItem.subtitle !== "Novosti sa utakmica" &&
        newsItem.subtitle !== "Novosti" &&
        newsItem.subtitle !== "Zanimljivosti"
      );
    } else {
      return newsItem.subtitle === filterSubtitle;
    }
  });

  const totalNews = filteredNewsData.length;
  const totalPages = Math.ceil(totalNews / newsPerPage);

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleFilterChange = (event) => {
    setFilterSubtitle(event.target.value);
    setCurrentPage(1);
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => handlePagination(i)}
          className={currentPage === i ? "active" : ""}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  const indexOfLastNews = currentPage * newsPerPage;
  const indexOfFirstNews = indexOfLastNews - newsPerPage;
  const currentNews = filteredNewsData
    .slice(indexOfFirstNews, indexOfLastNews)
    .map((newsItem) => <News key={newsItem.slug} slug={newsItem.slug} />);

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="NOVOSTI"
      />
      <div className="section">
        <div className="filter-controls">
          <label htmlFor="subtitleFilter">Filtriraj:</label>
          <select
            id="subtitleFilter"
            value={filterSubtitle}
            onChange={handleFilterChange}
            className="filter-btn"
          >
            <option value="Sve vijesti">Sve vijesti</option>
            <option value="Novosti sa utakmica">Novosti sa utakmica</option>
            <option value="Novosti">Novosti</option>
            <option value="Zanimljivosti">Zanimljivosti</option>
            <option value="Ostalo">Ostalo</option>
          </select>
        </div>
        <div className="grid-2 novosti-pages">{currentNews}</div>
        <div className="pagination">
          <button
            onClick={() =>
              handlePagination(currentPage > 1 ? currentPage - 1 : 1)
            }
            disabled={currentPage === 1}
          >
            &lt;
          </button>
          {renderPaginationButtons()}
          <button
            onClick={() =>
              handlePagination(
                currentPage < totalPages ? currentPage + 1 : totalPages
              )
            }
            disabled={currentPage === totalPages}
          >
            &gt;
          </button>
        </div>
      </div>
    </>
  );
}
