import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import newsData from "./NewsData";
// Images
import NKPodsusedBijelaBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Bg.jpg";
import NKPodsusedBijelaPhoneBg from "../../../Assets/Images/Ostalo/NK-Podsused-Bijela-Phone-Bg.jpg";
import NKPodsused from "../../../Assets/Images/Nk-Podsused-Logo.png";
import Main from "../Home/Main";

export default function NewsDetails() {
  const { slug } = useParams();
  const news = newsData.find((item) => item.slug === slug);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGoBack = () => {
    window.history.back();
  };

  if (!news) {
    return (
      <div className="news-not-found">
        <img src={NKPodsused} alt="NK Podsused" loading="lazy" />
        <h2>Novost nije pronađena!</h2>
        <Link to="/novosti" className="go-back-btn" onClick={handleGoBack}>
          Vrati se natrag
        </Link>
      </div>
    );
  }

  const preventContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Main
        desktopBackground={NKPodsusedBijelaBg}
        phoneBackground={NKPodsusedBijelaPhoneBg}
        title="NOVOSTI"
      />
      <div className="section">
        <div className="novosti-open">
          <Link to="/novosti" className="go-back-btn" onClick={handleGoBack}>
            Vrati se natrag
          </Link>

          {news.image && (
            <img
              className="novosti-open-img"
              src={news.image}
              alt={news.imageAlt}
              onContextMenu={preventContextMenu}
              style={{ userSelect: "none" }}
              loading="lazy"
            />
          )}
          <span className="img-author">{news.author}</span>
          <p className="subtitle">{news.subtitle}</p>
          <h2>{news.mainTitle}</h2>
          <div className="novosti-text">
            {news.bio1 && <p>{news.bio1}</p>}
            {news.bio2 && <p>{news.bio2}</p>}
            {news.bio3 && <p>{news.bio3}</p>}
            {news.bio4 && <p>{news.bio4}</p>}
            {news.bio5 && <p>{news.bio5}</p>}
            {news.bio6 && <p>{news.bio6}</p>}
          </div>
          <div className="novosti-text">
            {news.title1 && <h5>{news.title1}</h5>}
            {news.text1 && <p>{news.text1}</p>}
            {news.image1 && (
              <img
                src={news.image1}
                alt={news.imageAlt1}
                onContextMenu={preventContextMenu}
                style={{ userSelect: "none" }}
                loading="lazy"
              />
            )}
            {news.author1 && <span className="img-author">{news.author1}</span>}

            {news.title2 && <h5>{news.title2}</h5>}
            {news.text2 && <p>{news.text2}</p>}
            {news.image2 && (
              <img
                src={news.image2}
                alt={news.imageAlt2}
                onContextMenu={preventContextMenu}
                style={{ userSelect: "none" }}
                loading="lazy"
              />
            )}
            {news.author2 && <span className="img-author">{news.author2}</span>}

            {news.title3 && <h5>{news.title3}</h5>}
            {news.text3 && <p>{news.text3}</p>}
            {news.image3 && (
              <img
                src={news.image3}
                alt={news.imageAlt3}
                onContextMenu={preventContextMenu}
                style={{ userSelect: "none" }}
                loading="lazy"
              />
            )}
            {news.author3 && <span className="img-author">{news.author3}</span>}

            {news.title4 && <h5>{news.title4}</h5>}
            {news.text4 && <p>{news.text4}</p>}
            {news.image4 && (
              <img
                src={news.image4}
                alt={news.imageAlt4}
                onContextMenu={preventContextMenu}
                style={{ userSelect: "none" }}
                loading="lazy"
              />
            )}
            {news.author4 && <span className="img-author">{news.author4}</span>}

            {news.title5 && <h5>{news.title5}</h5>}
            {news.text5 && <p>{news.text5}</p>}
            {news.image5 && (
              <img
                src={news.image5}
                alt={news.imageAlt5}
                onContextMenu={preventContextMenu}
                style={{ userSelect: "none" }}
                loading="lazy"
              />
            )}
            {news.author5 && <span className="img-author">{news.author5}</span>}

            {news.title6 && <h5>{news.title6}</h5>}
            {news.text6 && <p>{news.text6}</p>}
            {news.image6 && (
              <img
                src={news.image6}
                alt={news.imageAlt6}
                onContextMenu={preventContextMenu}
                style={{ userSelect: "none" }}
                loading="lazy"
              />
            )}
            {news.author6 && <span className="img-author">{news.author6}</span>}

            {news.title7 && <h5>{news.title7}</h5>}
            {news.text7 && <p>{news.text7}</p>}
            {news.image7 && (
              <img
                src={news.image7}
                alt={news.imageAlt7}
                onContextMenu={preventContextMenu}
                style={{ userSelect: "none" }}
                loading="lazy"
              />
            )}
            {news.author7 && <span className="img-author">{news.author7}</span>}

            {news.title8 && <h5>{news.title8}</h5>}
            {news.text8 && <p>{news.text8}</p>}
            {news.image8 && (
              <img
                src={news.image8}
                alt={news.imageAlt8}
                onContextMenu={preventContextMenu}
                style={{ userSelect: "none" }}
                loading="lazy"
              />
            )}
            {news.author8 && <span className="img-author">{news.author8}</span>}

            {news.title9 && <h5>{news.title9}</h5>}
            {news.text9 && <p>{news.text9}</p>}
            {news.image9 && (
              <img
                src={news.image9}
                alt={news.imageAlt9}
                onContextMenu={preventContextMenu}
                style={{ userSelect: "none" }}
                loading="lazy"
              />
            )}
            {news.author9 && <span className="img-author">{news.author9}</span>}

            {news.title10 && <h5>{news.title10}</h5>}
            {news.text10 && <p>{news.text10}</p>}
            {news.image10 && (
              <img
                src={news.image10}
                alt={news.imageAlt10}
                onContextMenu={preventContextMenu}
                style={{ userSelect: "none" }}
                loading="lazy"
              />
            )}
            {news.author10 && (
              <span className="img-author">{news.author10}</span>
            )}

            {news.title11 && <h5>{news.title11}</h5>}
            {news.text11 && <p>{news.text11}</p>}
            {news.image11 && (
              <img
                src={news.image11}
                alt={news.imageAlt11}
                onContextMenu={preventContextMenu}
                style={{ userSelect: "none" }}
                loading="lazy"
              />
            )}
            {news.author11 && (
              <span className="img-author">{news.author11}</span>
            )}

            {news.title12 && <h5>{news.title12}</h5>}
            {news.text12 && <p>{news.text12}</p>}
            {news.image12 && (
              <img
                src={news.image12}
                alt={news.imageAlt12}
                onContextMenu={preventContextMenu}
                style={{ userSelect: "none" }}
                loading="lazy"
              />
            )}
            {news.author12 && (
              <span className="img-author">{news.author12}</span>
            )}

            {news.title13 && <h5>{news.title13}</h5>}
            {news.text13 && <p>{news.text13}</p>}
            {news.image13 && (
              <img
                src={news.image13}
                alt={news.imageAlt13}
                onContextMenu={preventContextMenu}
                style={{ userSelect: "none" }}
                loading="lazy"
              />
            )}
            {news.author13 && (
              <span className="img-author">{news.author13}</span>
            )}

            {news.title14 && <h5>{news.title14}</h5>}
            {news.text14 && <p>{news.text14}</p>}
            {news.image14 && (
              <img
                src={news.image14}
                alt={news.imageAlt14}
                onContextMenu={preventContextMenu}
                style={{ userSelect: "none" }}
                loading="lazy"
              />
            )}
            {news.author14 && (
              <span className="img-author">{news.author14}</span>
            )}

            {news.title15 && <h5>{news.title15}</h5>}
            {news.text15 && <p>{news.text15}</p>}
            {news.image15 && (
              <img
                src={news.image15}
                alt={news.imageAlt15}
                onContextMenu={preventContextMenu}
                style={{ userSelect: "none" }}
                loading="lazy"
              />
            )}
            {news.author15 && (
              <span className="img-author">{news.author15}</span>
            )}
            <div className="novosti-text">
              {news.endText && <p>{news.endText}</p>}
              <span>{news.date}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
